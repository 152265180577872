export const VALIDGUESSES = [
  'abaci',
  'abaja',
  'abece',
  'abëcë',
  'abort',
  'abses',
  'acare',
  'acari',
  'adash',
  'risht',
  'afërm',
  'rsira',
  'afërt',
  'afgan',
  'afion',
  'afras',
  'afrim',
  'afroj',
  'aftër',
  'sisht',
  'sohem',
  'agave',
  'rshak',
  'agoni',
  'agosh',
  'agrar',
  'agror',
  'agull',
  'agzot',
  'rojnë',
  'aheng',
  'ahere',
  'ahore',
  'ajdës',
  'ajërt',
  'matës',
  'ajkët',
  'tohet',
  'ajkoj',
  'ajnik',
  'ajrim',
  'ajroj',
  'ajror',
  'ajros',
  'ihane',
  'akëku',
  'akoma',
  'akord',
  'akrep',
  'aktiv',
  'aktor',
  'akull',
  'akuzë',
  'alarm',
  'albër',
  'album',
  'aleat',
  'alibi',
  'alkal',
  'alpin',
  'altar',
  'Allah',
  'allat',
  'allçe',
  'allçi',
  'allme',
  'allti',
  'amebë',
  'amësi',
  'shtiq',
  'amfib',
  'amorf',
  'amper',
  'amtar',
  'amton',
  'amull',
  'amvis',
  'anale',
  'anash',
  'anazi',
  'andej',
  'andri',
  'aneks',
  'anemi',
  'rqark',
  'anesë',
  'detas',
  'anëse',
  'anësi',
  'shtim',
  'shtoj',
  'anglo',
  'anije',
  'rtues',
  'anion',
  'ankim',
  'ankoj',
  'ankth',
  'anodë',
  'anore',
  'antik',
  'anuar',
  'anues',
  'anjëz',
  'aortë',
  'apaçi',
  'apati',
  'araba',
  'arape',
  'araqe',
  'arazi',
  'arbër',
  'arenë',
  'bërës',
  'argan',
  'argas',
  'argat',
  'tohem',
  'argsh',
  'arian',
  'arith',
  'arkas',
  'rkues',
  'arkëz',
  'arkiv',
  'armaç',
  'armen',
  'pishë',
  'armik',
  'armim',
  'armoj',
  'armor',
  'arnim',
  'arnoj',
  'aromë',
  'arsej',
  'arsim',
  'arsye',
  'artar',
  'artis',
  'artor',
  'arrës',
  'arrëz',
  'arrij',
  'arror',
  'arrtë',
  'asëll',
  'asgjë',
  'asker',
  'asket',
  'sohet',
  'asnjë',
  'aspak',
  'aspër',
  'asqer',
  'astar',
  'astmë',
  'astro',
  'ashar',
  'ashef',
  'ashër',
  'ashik',
  'ashkë',
  'rohem',
  'rsuar',
  'ashtë',
  'rohet',
  'ashtu',
  'atdhe',
  'gjysh',
  'atëri',
  'atësi',
  'atlas',
  'atlet',
  'atyre',
  'athem',
  'athët',
  'autor',
  'avari',
  'avash',
  'avdes',
  'avion',
  'avlli',
  'avore',
  'janar',
  'avrom',
  'avull',
  'azdis',
  'azgan',
  'Azoti',
  'azhdë',
  'azhur',
  'Dimri',
  'zisht',
  'zitem',
  'zitur',
  'babil',
  'bacil',
  'backë',
  'badem',
  'Baden',
  'badër',
  'bafër',
  'bager',
  'bagël',
  'bagëm',
  'bahçe',
  'bajat',
  'bajgë',
  'Curri',
  'bajun',
  'bajzë',
  'bakeq',
  'bakëm',
  'bakër',
  'rizim',
  'rosem',
  'rosur',
  'balet',
  'balër',
  'sorrë',
  'baloj',
  'balok',
  'balos',
  'baltë',
  'balun',
  'balla',
  'balle',
  'ballë',
  'çelik',
  'çelur',
  'flori',
  'gjerë',
  'hapët',
  'hapur',
  'lartë',
  'nxirë',
  'balli',
  'ballo',
  'njanë',
  'bambu',
  'bamje',
  'banak',
  'banal',
  'bandë',
  'banik',
  'banim',
  'bankë',
  'provë',
  'banoj',
  'banor',
  'banjë',
  'baqth',
  'baras',
  'bardh',
  'llemë',
  'llimë',
  'llosh',
  'bares',
  'barik',
  'rrasë',
  'barkë',
  'shuar',
  'barmë',
  'barni',
  'barok',
  'baron',
  'baror',
  'baros',
  'bartë',
  'barth',
  'barun',
  'barut',
  'barrë',
  'basen',
  'baski',
  'basmë',
  'roset',
  'fisas',
  'folës',
  'jetoj',
  'kohës',
  'kohor',
  'mbart',
  'mbyll',
  'ngjit',
  'punim',
  'punoj',
  'qenie',
  'short',
  'llore',
  'vëlla',
  'batak',
  'illëk',
  'betec',
  'batim',
  'batis',
  'batoj',
  'batoq',
  'bathë',
  'baule',
  'baxhë',
  'baxhi',
  'baxho',
  'bazal',
  'nisje',
  'bazik',
  'bazim',
  'bazoj',
  'bebëz',
  'bedel',
  'beden',
  'befas',
  'beftë',
  'begat',
  'begun',
  'behar',
  'bejçe',
  'bejkë',
  'bejte',
  'bekçi',
  'bekim',
  'bekoj',
  'belas',
  'belbe',
  'belcë',
  'belik',
  'putur',
  'beloj',
  'belor',
  'belot',
  'bemol',
  'beqar',
  'berëz',
  'plotë',
  'prerë',
  'tytni',
  'thyer',
  'thyes',
  'besim',
  'besoj',
  'betar',
  'betac',
  'beter',
  'betim',
  'betoj',
  'beton',
  'bezdi',
  'bezgë',
  'beztë',
  'bezhë',
  'bëhem',
  'ltacë',
  'ltajë',
  'lldum',
  'litje',
  'rbjek',
  'rdilë',
  'rdhac',
  'rhapë',
  'rhukë',
  'bërje',
  'rlutë',
  'rllok',
  'rnjak',
  'bërsi',
  'rshen',
  'vogël',
  'rxhik',
  'rzanë',
  'rrakë',
  'rrore',
  'bëzaj',
  'biban',
  'Bibla',
  'biçak',
  'biçim',
  'biçki',
  'bidon',
  'bigël',
  'bigët',
  'bigëz',
  'zohet',
  'bigoj',
  'bijim',
  'bijoj',
  'bijor',
  'bikër',
  'bikme',
  'bilan',
  'bilur',
  'bimël',
  'bimëz',
  'bimor',
  'bimsë',
  'binar',
  'binde',
  'binek',
  'binom',
  'biraç',
  'birbo',
  'birçe',
  'veshë',
  'birim',
  'birko',
  'biroj',
  'birrë',
  'biskë',
  'bishë',
  'bisht',
  'atall',
  'vizur',
  'bitis',
  'bitme',
  'bitum',
  'biule',
  'bixan',
  'bixhë',
  'bizgë',
  'bizon',
  'bjerr',
  'blacë',
  'blanë',
  'blatë',
  'blegë',
  'blerë',
  'bletë',
  'blidë',
  'blojë',
  'blozë',
  'bluaj',
  'bluar',
  'blues',
  'bluzë',
  'bllok',
  'bobël',
  'bobëz',
  'bobol',
  'bocun',
  'ëborë',
  'boçkë',
  'bodec',
  'boenë',
  'bogaz',
  'bogël',
  'allëk',
  'bohçe',
  'bohet',
  'finjë',
  'grurë',
  'gjake',
  'hurmë',
  'limon',
  'shegë',
  'tullë',
  'bojli',
  'bokël',
  'rimtë',
  'bolbë',
  'bollë',
  'bombë',
  'bonjë',
  'bonus',
  'borak',
  'inezë',
  'borde',
  'borës',
  'borik',
  'borit',
  'bormë',
  'borxh',
  'boskë',
  'bosti',
  'bosht',
  'botim',
  'botoj',
  'botor',
  'braci',
  'brajë',
  'bramc',
  'branë',
  'bravë',
  'bravo',
  'hapës',
  'bredh',
  'brejë',
  'brekë',
  'jerrë',
  'brenë',
  'shumë',
  'bresë',
  'llirë',
  'ritje',
  'bretk',
  'brevë',
  'mbrek',
  'bricë',
  'briçe',
  'rrabë',
  'brimë',
  'brisë',
  'brisk',
  'Madhe',
  'britm',
  'brith',
  'brizë',
  'brokë',
  'bronk',
  'bronz',
  'brukë',
  'brumç',
  'brumë',
  'bruto',
  'brydh',
  'bryme',
  'brymë',
  'bryth',
  'buacë',
  'buall',
  'bubël',
  'bubëz',
  'bucak',
  'bucem',
  'buçet',
  'karan',
  'karit',
  'buçkë',
  'buçko',
  'buçuk',
  'bufal',
  'bufar',
  'bufit',
  'bufkë',
  'bufon',
  'buhar',
  'buhit',
  'buhur',
  'bujar',
  'bukas',
  'dhënë',
  'haram',
  'bukël',
  'rmbys',
  'rrime',
  'bukës',
  'bukëz',
  'bukje',
  'bukoj',
  'bukur',
  'llues',
  'bulas',
  'bulbe',
  'bulçi',
  'bulëz',
  'bulgë',
  'bulim',
  'bulon',
  'bullë',
  'bumbë',
  'bunar',
  'bundë',
  'bungë',
  'leshe',
  'buqar',
  'buqme',
  'burim',
  'burmë',
  'buron',
  'bursë',
  'burth',
  'burrë',
  'reshë',
  'burri',
  'bushk',
  'butac',
  'butak',
  'butër',
  'butik',
  'butoj',
  'buzaç',
  'buzak',
  'buzas',
  'buzel',
  'fryrë',
  'hollë',
  'kyqur',
  'buzël',
  'lepur',
  'madhe',
  'rripë',
  'tharë',
  'thatë',
  'varur',
  'buzëz',
  'buzik',
  'buzje',
  'buzoç',
  'buzor',
  'buzuk',
  'bylme',
  'bylyk',
  'bymej',
  'bymim',
  'byrde',
  'byrek',
  'bythë',
  'kromë',
  'qiqër',
  'cabik',
  'cabok',
  'cacër',
  'cajkë',
  'cakos',
  'calik',
  'rdhok',
  'cangë',
  'rluar',
  'capim',
  'capit',
  'capoj',
  'capok',
  'carac',
  'caran',
  'cedër',
  'cefël',
  'cegëm',
  'cekem',
  'cekët',
  'cekim',
  'cekje',
  'cekoj',
  'cekth',
  'cemtë',
  'cenak',
  'cengë',
  'cenim',
  'cenoj',
  'cenos',
  'centi',
  'cepik',
  'cergë',
  'cermë',
  'cëpëz',
  'cërit',
  'ritur',
  'rkatë',
  'rkazi',
  'cërkë',
  'rmëli',
  'rulak',
  'cërre',
  'rrfat',
  'cibël',
  'cibun',
  'cifël',
  'cigan',
  'cijat',
  'cikas',
  'cikem',
  'cikël',
  'rrimë',
  'cikmë',
  'ciknë',
  'cikol',
  'cikon',
  'cikth',
  'cilik',
  'cimak',
  'cimar',
  'cingë',
  'lohem',
  'risem',
  'risje',
  'risur',
  'cingo',
  'cinik',
  'cinkë',
  'cipal',
  'cipëz',
  'cipje',
  'ciqër',
  'cirka',
  'cirkë',
  'cirlë',
  'cirua',
  'citat',
  'citem',
  'citim',
  'citoj',
  'citos',
  'civil',
  'cjape',
  'cofët',
  'cohtë',
  'cokat',
  'cokël',
  'tuese',
  'copëz',
  'copit',
  'corkë',
  'crogë',
  'cubak',
  'cuban',
  'cubar',
  'cubel',
  'cubni',
  'cuboj',
  'rrosh',
  'cuckë',
  'cufël',
  'cukël',
  'lohet',
  'culan',
  'cullë',
  'cumër',
  'cungë',
  'rrelë',
  'cupil',
  'curle',
  'curre',
  'currë',
  'cylaq',
  'cylek',
  'cytem',
  'cytës',
  'cytje',
  'cytur',
  'çaçkë',
  'çadër',
  'çafër',
  'çafkë',
  'thime',
  'çahem',
  'çahje',
  'çajme',
  'ajore',
  'ukthi',
  'akall',
  'çakël',
  'akëll',
  'çakër',
  'akërr',
  'akmak',
  'akthi',
  'mbëzi',
  'çalas',
  'alash',
  'çalëz',
  'çalim',
  'çaloj',
  'çalok',
  'althi',
  'çallë',
  'stiar',
  'allëz',
  'allmë',
  'alloj',
  'çamër',
  'amëri',
  'çanak',
  'pirës',
  'andër',
  'çangë',
  'çantë',
  'çapar',
  'apare',
  'çapem',
  'lajkë',
  'çapëz',
  'çapit',
  'apkën',
  'çapok',
  'apraz',
  'apthi',
  'çapua',
  'arçaf',
  'ardak',
  'çarës',
  'arëse',
  'çarje',
  'çarki',
  'arshi',
  'artem',
  'çartë',
  'artje',
  'artoj',
  'ashëm',
  'çatis',
  'çatma',
  'atmoj',
  'çaush',
  'çefkë',
  'çehre',
  'çekan',
  'çekem',
  'Çekia',
  'çekiç',
  'çekje',
  'çekth',
  'çelem',
  'çelës',
  'çelët',
  'çelëz',
  'çelje',
  'elnik',
  'eltas',
  'eltik',
  'çemer',
  'endet',
  'endje',
  'engel',
  'çengi',
  'çepka',
  'epkat',
  'epojë',
  'erçem',
  'erdhe',
  'çerek',
  'ereke',
  'çerep',
  'çerte',
  'eshit',
  'çetar',
  'etash',
  'etele',
  'çetëz',
  'çetim',
  'etinë',
  'çetoj',
  'çetur',
  'çevre',
  'çezmë',
  'njtem',
  'njtje',
  'njtur',
  'shtje',
  'çfarë',
  'çiban',
  'çibuk',
  'icërr',
  'idikë',
  'çifçi',
  'iflig',
  'iftar',
  'iftas',
  'çifte',
  'iftëz',
  'zohem',
  'iftim',
  'iftoj',
  'çifut',
  'ikash',
  'çikem',
  'çiket',
  'çikël',
  'ikërr',
  'çikët',
  'çikëz',
  'çikje',
  'çikma',
  'çikoj',
  'ikrik',
  'çikul',
  'çilak',
  'ilash',
  'çilek',
  'çilem',
  'ilimi',
  'iltas',
  'iltër',
  'çilur',
  'imërr',
  'çimkë',
  'inërr',
  'çinoj',
  'çipës',
  'çirak',
  'irrem',
  'irrmë',
  'çitar',
  'çivit',
  'çizme',
  'çjerr',
  'kalit',
  'çlidh',
  'lirët',
  'lirim',
  'liroj',
  'çlodh',
  'lyros',
  'mahem',
  'çmall',
  'matem',
  'mbreh',
  'mbush',
  'mekem',
  'çmend',
  'merim',
  'merit',
  'çmesë',
  'çmërs',
  'mësim',
  'mësoj',
  'minim',
  'minoj',
  'mohet',
  'çmpij',
  'mpreh',
  'çmuar',
  'çmues',
  'çnduk',
  'çnjeh',
  'çnjoh',
  'çoban',
  'obane',
  'obani',
  'çoçër',
  'çohem',
  'çohet',
  'okale',
  'okane',
  'çokas',
  'çokëz',
  'çokit',
  'okush',
  'omlek',
  'çorap',
  'çorbë',
  'orodi',
  'çrras',
  'rrudh',
  'uarje',
  'uçlon',
  'urimë',
  'urjar',
  'çudit',
  'çudoj',
  'çufër',
  'ufrak',
  'çukël',
  'çukit',
  'çulzë',
  'umbër',
  'çunak',
  'unëri',
  'upëri',
  'çupit',
  'urgon',
  'çurkë',
  'çyrek',
  'çyryk',
  'çysht',
  'dackë',
  'dahuk',
  'dajak',
  'dajan',
  'dajre',
  'dakol',
  'dalës',
  'dalje',
  'daltë',
  'damar',
  'damaz',
  'damkë',
  'danez',
  'dangë',
  'danik',
  'darkë',
  'dasik',
  'dasmë',
  'dashe',
  'datim',
  'datoj',
  'debat',
  'debit',
  'debil',
  'tendë',
  'degëz',
  'dehem',
  'dehet',
  'dehës',
  'dehje',
  'dehur',
  'deist',
  'dejçë',
  'dejëz',
  'dejkë',
  'dekan',
  'dekor',
  'delme',
  'deltë',
  'demek',
  'demet',
  'demon',
  'denik',
  'denjë',
  'derdh',
  'kyçur',
  'derës',
  'derëz',
  'derre',
  'detal',
  'detar',
  'dëbim',
  'dëboj',
  'buhet',
  'fryes',
  'ftesë',
  'ftore',
  'ftues',
  'gjesë',
  'gjuar',
  'gjues',
  'dëkim',
  'kohem',
  'dëkoj',
  'dëlir',
  'lirem',
  'lirës',
  'lirje',
  'ngthi',
  'mshëm',
  'rblej',
  'rblim',
  'mtuar',
  'mtues',
  'dënes',
  'nesem',
  'dënga',
  'ngëza',
  'dënim',
  'dënoj',
  'rdëng',
  'rgesë',
  'rguar',
  'rgues',
  'rsirë',
  'rstil',
  'dërti',
  'rrmaç',
  'rrmak',
  'hasem',
  'rrmim',
  'rrmoj',
  'shirë',
  'shmim',
  'shmoj',
  'shmor',
  'shtak',
  'dibël',
  'Dibër',
  'diçka',
  'diell',
  'dietë',
  'dihat',
  'dihet',
  'dijës',
  'dikoj',
  'dikur',
  'dinak',
  'dinar',
  'diodë',
  'direk',
  'ditar',
  'gjatë',
  'ditën',
  'sosur',
  'ditoj',
  'ditor',
  'ditur',
  'divan',
  'divit',
  'dizgë',
  'djalë',
  'djall',
  'djegë',
  'djerr',
  'dobët',
  'dobiç',
  'dockë',
  'doçkë',
  'dogmë',
  'dogra',
  'doker',
  'dolli',
  'domen',
  'domkë',
  'dorac',
  'dorak',
  'doras',
  'fortë',
  'hekur',
  'heqës',
  'heqje',
  'lehtë',
  'mbarë',
  'menjë',
  'piper',
  'prapë',
  'rëndë',
  'dorës',
  'nguar',
  'dorëz',
  'zanës',
  'zanoj',
  'zonjë',
  'dorik',
  'dorje',
  'dosar',
  'dosëz',
  'dosje',
  'dozim',
  'dozoj',
  'dragë',
  'dramë',
  'drang',
  'dredh',
  'dregë',
  'drejt',
  'ndësh',
  'ndrim',
  'drekë',
  'drenë',
  'dreqe',
  'dreth',
  'drill',
  'Drini',
  'dritë',
  'shues',
  'mekët',
  'drizë',
  'drogë',
  'drojë',
  'droqe',
  'druaj',
  'drudh',
  'drugë',
  'Dubai',
  'duduk',
  'dudum',
  'duhaç',
  'duhan',
  'duhem',
  'duhmë',
  'duhur',
  'dujkë',
  'dukal',
  'dukat',
  'dukem',
  'dukje',
  'dukur',
  'duman',
  'dumen',
  'dumkë',
  'dungë',
  'dupje',
  'durim',
  'duroj',
  'dushk',
  'duvak',
  'dybek',
  'mbësh',
  'dyfek',
  'dyjar',
  'dylbi',
  'dyllë',
  'çitet',
  'çitur',
  'dynym',
  'dynja',
  'dyqan',
  'dysor',
  'dystë',
  'dyshe',
  'dyzen',
  'dyzet',
  'dyzim',
  'dyzoj',
  'dheas',
  'rmues',
  'dhele',
  'rushe',
  'dhemb',
  'dheul',
  'dhëmb',
  'mbçim',
  'mbçoj',
  'ruese',
  'qitur',
  'shatë',
  'mbore',
  'ndëri',
  'dhiar',
  'dhiri',
  'dhisk',
  'dhiza',
  'dhjak',
  'dhjam',
  'dhjes',
  'mujor',
  'dhogë',
  'dhomë',
  'dhrim',
  'dhunë',
  'ecuri',
  'efekt',
  'rshan',
  'egras',
  'egjër',
  'ehull',
  'ekran',
  'eksiq',
  'elbje',
  'elbtë',
  'elbth',
  'elips',
  'elitë',
  'elmaz',
  'elozh',
  'rmadh',
  'rzuar',
  'emnak',
  'endem',
  'endës',
  'endëz',
  'endth',
  'endur',
  'enemi',
  'larës',
  'llush',
  'enkas',
  'epërm',
  'epikë',
  'Epiri',
  'epokë',
  'epope',
  'epror',
  'eptim',
  'erdha',
  'erëza',
  'ermik',
  'erore',
  'errem',
  'errët',
  'esëll',
  'esnaf',
  'estet',
  'eshkë',
  'eshtë',
  'etapë',
  'etikë',
  'etnik',
  'ethje',
  'eunuk',
  'evari',
  'evlat',
  'ezmer',
  'ëmbël',
  'lsirë',
  'lsuar',
  'ltore',
  'ëndet',
  'ndërr',
  'rrtar',
  'rruar',
  'rrues',
  'ëndje',
  'është',
  'fabul',
  'fagot',
  'fajde',
  'fajkë',
  'fakir',
  'falas',
  'falem',
  'falëz',
  'falje',
  'falmë',
  'fanar',
  'faqëz',
  'faqoj',
  'faqor',
  'faran',
  'farec',
  'farës',
  'sendi',
  'farëz',
  'farje',
  'farkë',
  'faroj',
  'faror',
  'farsë',
  'fashë',
  'fatal',
  'fatos',
  'fyese',
  'fatzi',
  'faunë',
  'favor',
  'fazan',
  'feçkë',
  'fejoj',
  'fejzë',
  'femër',
  'fendë',
  'fener',
  'fermë',
  'ferrë',
  'manzë',
  'feste',
  'festë',
  'fetar',
  'lligë',
  'llizë',
  'fërli',
  'rtele',
  'rtomë',
  'rtymë',
  'rratë',
  'fibër',
  'ficet',
  'ficur',
  'fiçor',
  'fidan',
  'fidhe',
  'Fieri',
  'fijez',
  'fijoj',
  'fijor',
  'fikat',
  'fikës',
  'fikët',
  'fikth',
  'fikur',
  'filan',
  'filiç',
  'filiz',
  'final',
  'finok',
  'fiqir',
  'firmë',
  'firoj',
  'fisëm',
  'fishk',
  'llyes',
  'fitëz',
  'fitil',
  'fitim',
  'fitme',
  'fitoj',
  'fizik',
  'fjalë',
  'fjala',
  'matur',
  'paktë',
  'rkuzë',
  'fjali',
  'fjord',
  'flakë',
  'ritem',
  'vënës',
  'flamë',
  'ngarë',
  'flaut',
  'hyrje',
  'rkesë',
  'rënës',
  'rruge',
  'votim',
  'urrel',
  'florë',
  'flotë',
  'flugë',
  'fluor',
  'fllad',
  'folje',
  'folme',
  'folur',
  'follë',
  'forcë',
  'formë',
  'forum',
  'fosil',
  'foton',
  'fqinj',
  'eskan',
  'frang',
  'frazë',
  'fresk',
  'freth',
  'frezë',
  'frëng',
  'frikë',
  'front',
  'frutë',
  'fruth',
  'frymë',
  'qitës',
  'qitje',
  'fryzë',
  'fshaj',
  'fshat',
  'fsheh',
  'fshij',
  'fshik',
  'ftorr',
  'ftuar',
  'ftujë',
  'fugem',
  'fugim',
  'fugoj',
  'fulqi',
  'rresë',
  'rrinë',
  'furan',
  'furçë',
  'furde',
  'furem',
  'furkë',
  'furrë',
  'fustë',
  'fushë',
  'gropë',
  'pamje',
  'fushk',
  'futem',
  'futës',
  'futje',
  'futur',
  'fyçkë',
  'fyell',
  'fyhem',
  'fyrbë',
  'fyrlë',
  'fytak',
  'fytas',
  'fytës',
  'fytëz',
  'fytje',
  'vrarë',
  'zymtë',
  'gabim',
  'gaboj',
  'gaçkë',
  'gagaç',
  'gajde',
  'gajgë',
  'gajle',
  'galop',
  'ganez',
  'garbë',
  'garbi',
  'gardë',
  'gardh',
  'garzë',
  'gatër',
  'gatim',
  'gatit',
  'gavër',
  'gaviç',
  'gaxhi',
  'gazel',
  'gazep',
  'gdhij',
  'gemtë',
  'germë',
  'gëlon',
  'lqere',
  'lltit',
  'mushë',
  'rbulë',
  'gërdi',
  'rdhuq',
  'rhalë',
  'rhanë',
  'rnetë',
  'rshet',
  'rthas',
  'rvimë',
  'gërxh',
  'rxhel',
  'rxhor',
  'rzhim',
  'rzhit',
  'rryej',
  'rryes',
  'shill',
  'gëzim',
  'gëzof',
  'gëzoj',
  'zhojë',
  'zhutë',
  'gican',
  'gisht',
  'glasë',
  'gletë',
  'gliko',
  'glinë',
  'gocan',
  'gocar',
  'godas',
  'godit',
  'gogël',
  'gogol',
  'gojac',
  'gojaç',
  'golle',
  'gomar',
  'gomoj',
  'gorar',
  'gorec',
  'gorre',
  'gosti',
  'goshë',
  'gotik',
  'goxha',
  'gracë',
  'gradë',
  'grafi',
  'grari',
  'graso',
  'grath',
  'gresë',
  'greth',
  'grevë',
  'gribë',
  'grifë',
  'grihë',
  'grill',
  'grind',
  'grish',
  'grivë',
  'groll',
  'grosh',
  'moshë',
  'grykë',
  'grror',
  'guacë',
  'guall',
  'gufoj',
  'gugat',
  'guhak',
  'gulfë',
  'gungë',
  'gurrë',
  'gushë',
  'guxim',
  'guxoj',
  'gypor',
  'rlyer',
  'gjanë',
  'gjani',
  'gjasë',
  'gjazë',
  'gjedh',
  'gjegj',
  'gjemb',
  'gjemi',
  'gjeni',
  'gjerb',
  'gjeri',
  'gjest',
  'gjete',
  'gjeth',
  'kundi',
  'llime',
  'gjëmë',
  'mëtar',
  'gjëzë',
  'gjind',
  'gjini',
  'gjips',
  'gjizë',
  'gjobë',
  'gjoja',
  'gjoke',
  'gjoks',
  'gjoll',
  'gjorë',
  'gjuaj',
  'gjuhë',
  'nduar',
  'thikë',
  'gjumë',
  'tisem',
  'lënës',
  'gjyle',
  'lëmsh',
  'malor',
  'njeri',
  'peshk',
  'rçues',
  'rreth',
  'sferë',
  'vezak',
  'haber',
  'habit',
  'hafëz',
  'hafif',
  'hahem',
  'hajat',
  'hajde',
  'hajni',
  'hakoj',
  'halëz',
  'haliç',
  'halor',
  'halth',
  'hallë',
  'halli',
  'hallk',
  'hamam',
  'Hamas',
  'hamës',
  'hanko',
  'hapem',
  'sinor',
  'hapje',
  'haraç',
  'harar',
  'harbi',
  'harem',
  'harmi',
  'harpë',
  'hartë',
  'harxh',
  'harrë',
  'haset',
  'hasër',
  'hasha',
  'hatër',
  'havan',
  'haxhi',
  'hazër',
  'hebre',
  'hedhë',
  'hejbe',
  'hejdë',
  'hejzë',
  'helaq',
  'helen',
  'helme',
  'hequr',
  'herak',
  'herës',
  'herët',
  'herre',
  'hesap',
  'hesht',
  'hetim',
  'hetoj',
  'nakeq',
  'hënëz',
  'hënor',
  'përhë',
  'hidër',
  'hidro',
  'hienë',
  'hijëz',
  'hijor',
  'himtë',
  'hinkë',
  'hiper',
  'hipik',
  'hipje',
  'hipur',
  'hiqem',
  'hirës',
  'hiroj',
  'hirtë',
  'hirrë',
  'hojëz',
  'hokej',
  'hokoj',
  'holla',
  'holli',
  'horre',
  'hotel',
  'hovas',
  'hoxhë',
  'huall',
  'huaza',
  'huazi',
  'hukat',
  'hulli',
  'humai',
  'humbë',
  'humor',
  'humus',
  'hunap',
  'hunde',
  'hundë',
  'kalem',
  'rrutë',
  'hupës',
  'hupët',
  'hurbë',
  'hutaq',
  'rrojë',
  'hutim',
  'hutin',
  'hutoj',
  'hyjni',
  'hyrës',
  'rshim',
  'ibrik',
  'ickël',
  'ideal',
  'ideor',
  'rshah',
  'idiot',
  'idhcë',
  'ikonë',
  'ildis',
  'ilnjë',
  'imazh',
  'imtas',
  'imtim',
  'imtoj',
  'imzot',
  'India',
  'indoj',
  'indor',
  'intim',
  'inxhi',
  'iridë',
  'irizë',
  'irnim',
  'irnoj',
  'irnos',
  'ironi',
  'islam',
  'istëm',
  'jahni',
  'jakëz',
  'jalli',
  'janki',
  'jaran',
  'jargë',
  'jatak',
  'javor',
  'jazëk',
  'jehon',
  'jelek',
  'jepem',
  'jesir',
  'jetik',
  'jetim',
  'jetos',
  'jevgë',
  'jodur',
  'joshë',
  'jufkë',
  'jugor',
  'juntë',
  'kabëz',
  'kabot',
  'kacan',
  'kacek',
  'kacer',
  'kacit',
  'kacuk',
  'aberr',
  'kaçak',
  'amill',
  'anduk',
  'arret',
  'avidë',
  'kaçel',
  'irubë',
  'kaçkë',
  'kaçul',
  'ulitë',
  'ulore',
  'ullar',
  'kaçup',
  'kadet',
  'kadhë',
  'kafaz',
  'kafkë',
  'katit',
  'kaike',
  'kaish',
  'kajlë',
  'kajsi',
  'kakao',
  'zogëz',
  'kakin',
  'kalas',
  'sitet',
  'zehet',
  'shalë',
  'kalif',
  'kalim',
  'kaloj',
  'rremë',
  'kaluç',
  'kalla',
  'kalli',
  'kallm',
  'kallo',
  'kallp',
  'kamës',
  'kamje',
  'kamur',
  'kanal',
  'kanat',
  'kanos',
  'kanun',
  'kapak',
  'kapan',
  'kapar',
  'kapem',
  'rcell',
  'rcyer',
  'rthej',
  'rthim',
  'kapës',
  'kapëz',
  'kapit',
  'kapje',
  'kaptë',
  'kapth',
  'kapuç',
  'kaqol',
  'karar',
  'karat',
  'kariq',
  'karkë',
  'karmë',
  'karpë',
  'kartë',
  'karro',
  'kasap',
  'kaskë',
  'kastë',
  'rmenë',
  'katac',
  'katet',
  'katëk',
  'katër',
  'rfish',
  'rqind',
  'rshor',
  'katil',
  'katoq',
  'katua',
  'kaull',
  'kaush',
  'kauzë',
  'kavak',
  'kazan',
  'kazil',
  'kazmë',
  'kecan',
  'keltë',
  'kepcë',
  'kepës',
  'kepët',
  'kepje',
  'keqan',
  'keqas',
  'keqem',
  'ketër',
  'lbazë',
  'lboqe',
  'lkazë',
  'lthas',
  'këlli',
  'këllk',
  'këmba',
  'mbale',
  'mbesë',
  'këmbë',
  'kithi',
  'pupël',
  'mbëse',
  'thirë',
  'mborë',
  'mbyer',
  'mbyes',
  'mendë',
  'mishë',
  'qepës',
  'kënaq',
  'naqem',
  'naqje',
  'naqur',
  'ndell',
  'këndë',
  'ndirr',
  'ndore',
  'nellë',
  'netor',
  'këngë',
  'kënut',
  'pucar',
  'pujor',
  'pushë',
  'këput',
  'putem',
  'putës',
  'putje',
  'këqyr',
  'qyrem',
  'qyrje',
  'rbunj',
  'rcacë',
  'kërce',
  'nohem',
  'kërci',
  'kërcu',
  'rcure',
  'rçabë',
  'anjar',
  'kërdi',
  'rkaçe',
  'rkuar',
  'kërmë',
  'rmill',
  'rpesh',
  'rpicë',
  'rpore',
  'rtole',
  'rthët',
  'rvesh',
  'rriçe',
  'rrigë',
  'rroçe',
  'kësaç',
  'kësaj',
  'kësen',
  'sisoj',
  'suler',
  'këtej',
  'tejmë',
  'këtij',
  'tillë',
  'kijat',
  'kikël',
  'kikëz',
  'kilus',
  'kilzë',
  'killë',
  'kimet',
  'kimik',
  'kinez',
  'kinse',
  'kishë',
  'kishk',
  'kjomë',
  'klasë',
  'klekë',
  'klikë',
  'klimë',
  'klitë',
  'klith',
  'kloun',
  'kmesë',
  'kobar',
  'kobër',
  'kobit',
  'kobzi',
  'kocak',
  'kocan',
  'kockë',
  'allak',
  'koçan',
  'koçek',
  'imare',
  'irënë',
  'obash',
  'omila',
  'kodër',
  'rvarr',
  'kodoj',
  'kofin',
  'kojkë',
  'kokël',
  'rdhak',
  'rrotë',
  'rrzim',
  'rrzoj',
  'rrzor',
  'kokës',
  'kokët',
  'pjetë',
  'zhabë',
  'kokme',
  'koleg',
  'kolës',
  'kolik',
  'kolit',
  'kolmë',
  'kolon',
  'kolos',
  'kollë',
  'kombe',
  'komçë',
  'komik',
  'komit',
  'komtë',
  'konak',
  'Kongo',
  'konik',
  'konop',
  'kopan',
  'kopek',
  'kopër',
  'kopil',
  'kopje',
  'kopsë',
  'kopuk',
  'koqëz',
  'koqit',
  'koral',
  'koran',
  'koraq',
  'koras',
  'korbe',
  'korbë',
  'Korça',
  'kordë',
  'Korea',
  'koret',
  'korit',
  'korse',
  'korte',
  'korra',
  'kosar',
  'kosit',
  'kosto',
  'kotas',
  'kotec',
  'kotem',
  'kovaç',
  'krape',
  'krecë',
  'kreçe',
  'kredi',
  'kredo',
  'kredh',
  'krejt',
  'krekë',
  'kreko',
  'krela',
  'kresë',
  'krevë',
  'krezm',
  'krënd',
  'krifë',
  'krimb',
  'kripë',
  'krisë',
  'krizë',
  'kroat',
  'krocë',
  'krosë',
  'kruaj',
  'krues',
  'krupë',
  'krybe',
  'krydh',
  'kryej',
  'kryer',
  'kryqe',
  'zatës',
  'krrëk',
  'krrok',
  'kthej',
  'kthim',
  'kuacë',
  'kuant',
  'kuarc',
  'kuban',
  'kubël',
  'kubik',
  'kuckë',
  'kuçkë',
  'kudër',
  'kufje',
  'kufkë',
  'kuhem',
  'kujri',
  'kukan',
  'kukat',
  'kukëz',
  'kukoj',
  'kulaç',
  'kular',
  'kulet',
  'kulim',
  'kullë',
  'kumac',
  'kumak',
  'kumar',
  'kumet',
  'kumri',
  'kunat',
  'rhelm',
  'rmasë',
  'symës',
  'symje',
  'rpadi',
  'rsulm',
  'rshti',
  'rtank',
  'rtezë',
  'rtrup',
  'rrymë',
  'kunel',
  'kungë',
  'kuotë',
  'kupac',
  'kupëz',
  'kupon',
  'kupor',
  'kuqal',
  'kuqar',
  'kuqem',
  'kuqël',
  'kuqon',
  'kuqur',
  'kurdo',
  'kurim',
  'kurje',
  'kuroj',
  'kurse',
  'kurth',
  'kurrë',
  'kusar',
  'kusur',
  'kusht',
  'kuvar',
  'kuvli',
  'kyçem',
  'enicë',
  'labçe',
  'labot',
  'lacan',
  'lacër',
  'laçkë',
  'ladut',
  'lagem',
  'lagës',
  'lagët',
  'lagie',
  'lagur',
  'lagje',
  'lahem',
  'lahur',
  'lajle',
  'lakas',
  'lakat',
  'lakeç',
  'lakër',
  'rarme',
  'lakës',
  'lakim',
  'lakmi',
  'lakoç',
  'lakoj',
  'lakor',
  'laktë',
  'lakth',
  'lakut',
  'lanet',
  'lanok',
  'Laosi',
  'laper',
  'lapër',
  'rdhar',
  'larak',
  'laran',
  'larëz',
  'largu',
  'larik',
  'larim',
  'laris',
  'larje',
  'larmë',
  'larmi',
  'laroj',
  'larok',
  'laros',
  'larti',
  'larth',
  'larua',
  'laruk',
  'larvë',
  'lashë',
  'lasht',
  'latar',
  'latim',
  'latin',
  'latoj',
  'lator',
  'laurë',
  'lavaç',
  'lavar',
  'lavdi',
  'lavër',
  'rtari',
  'lavor',
  'lavos',
  'lebër',
  'lecka',
  'leckë',
  'ledhë',
  'legal',
  'legen',
  'lehje',
  'lehur',
  'lejim',
  'lejoj',
  'lejor',
  'lekën',
  'lemzë',
  'lende',
  'lendi',
  'lenoj',
  'lepec',
  'leqoj',
  'leros',
  'lerth',
  'lesim',
  'lesoj',
  'letër',
  'leton',
  'lexim',
  'lexoj',
  'lezar',
  'lezet',
  'Lezha',
  'barke',
  'lëbyr',
  'byrem',
  'byrës',
  'byrje',
  'byrtë',
  'lëçit',
  'çitës',
  'çitje',
  'lëfar',
  'farem',
  'lëfos',
  'lëfyt',
  'kitër',
  'lëkoq',
  'lëkua',
  'lëkuq',
  'lëkur',
  'kurçe',
  'kurem',
  'kurës',
  'kurëz',
  'kuror',
  'kurtë',
  'kurur',
  'lëmak',
  'lëmar',
  'mashk',
  'lëmek',
  'mekur',
  'lëmes',
  'mesem',
  'lëmim',
  'mohem',
  'lëmoj',
  'mshor',
  'lëmuq',
  'myshk',
  'lëndë',
  'lëndi',
  'ndinë',
  'ndues',
  'ndyrë',
  'neshë',
  'ngatë',
  'ngesë',
  'lëngë',
  'nguth',
  'lënie',
  'lënur',
  'nurem',
  'nurje',
  'nurur',
  'pihem',
  'lëpij',
  'pirje',
  'piskë',
  'lërej',
  'lërim',
  'lëroj',
  'lëshe',
  'shesë',
  'shojë',
  'lëvar',
  'varem',
  'varës',
  'vdatë',
  'vduar',
  'vdues',
  'verdh',
  'lëviz',
  'vizës',
  'vizje',
  'vizor',
  'voroj',
  'vozhg',
  'vrizë',
  'vruar',
  'vrues',
  'libër',
  'Libia',
  'ligaç',
  'ligat',
  'ligem',
  'ligët',
  'ligur',
  'ligje',
  'rimor',
  'lihem',
  'lijim',
  'lijoj',
  'lijos',
  'liken',
  'liker',
  'lilak',
  'lilth',
  'liman',
  'limbë',
  'limer',
  'limët',
  'limëz',
  'limfë',
  'limim',
  'limit',
  'limoj',
  'lingë',
  'linjë',
  'liqen',
  'liqos',
  'lirar',
  'lirik',
  'lisën',
  'lismë',
  'lisni',
  'listë',
  'litar',
  'litër',
  'loçkë',
  'lodër',
  'logoj',
  'lojbë',
  'lojës',
  'lokal',
  'lolit',
  'lopar',
  'lopor',
  'lorëz',
  'lotim',
  'lotoj',
  'lozak',
  'lozhë',
  'luadh',
  'luari',
  'luftë',
  'lugaç',
  'lugat',
  'lugës',
  'lugët',
  'lugëz',
  'lugim',
  'lugoj',
  'lugor',
  'lugth',
  'lugur',
  'luhat',
  'luhet',
  'lukër',
  'lukth',
  'llagë',
  'lulëz',
  'lulim',
  'luloj',
  'lumak',
  'lumor',
  'lumos',
  'lumtë',
  'lumth',
  'lungë',
  'lurek',
  'lurkë',
  'luspë',
  'lushë',
  'lutem',
  'lutës',
  'lutje',
  'lutur',
  'luzmë',
  'lyhem',
  'lymak',
  'lymor',
  'lymtë',
  'lypet',
  'lypës',
  'lypje',
  'lyrem',
  'lyrës',
  'lyrët',
  'lyrim',
  'lyroj',
  'llacë',
  'llajë',
  'llakë',
  'llapa',
  'llapë',
  'rçinë',
  'rkajë',
  'llaps',
  'llasë',
  'llauz',
  'llavë',
  'llërë',
  'llohë',
  'llome',
  'llucë',
  'llukë',
  'llumë',
  'llupë',
  'lluqe',
  'maçkë',
  'maçok',
  'madem',
  'rgonë',
  'madje',
  'shtor',
  'mafes',
  'mafër',
  'magmë',
  'magje',
  'magji',
  'mahis',
  'mahit',
  'majça',
  'majmë',
  'major',
  'majta',
  'majtë',
  'majth',
  'majuc',
  'maker',
  'maket',
  'makër',
  'makro',
  'makth',
  'makut',
  'sorçe',
  'malok',
  'ngjej',
  'ngjim',
  'mamuz',
  'manak',
  'manar',
  'mangë',
  'manto',
  'manjë',
  'maraj',
  'maraq',
  'maraz',
  'mardh',
  'markë',
  'marsh',
  'martë',
  'marre',
  'marrë',
  'marri',
  'masat',
  'masiv',
  'maskë',
  'mason',
  'masur',
  'mashë',
  'matje',
  'matkë',
  'matuf',
  'maunë',
  'mazit',
  'mazut',
  'mazhë',
  'mbanë',
  'mbars',
  'mbase',
  'mbasi',
  'mbath',
  'mbëhi',
  'shtet',
  'qyrës',
  'mbirë',
  'mblat',
  'mbles',
  'mbret',
  'manet',
  'mbroj',
  'mbruj',
  'mbrus',
  'mburr',
  'kadin',
  'meçkë',
  'medet',
  'medie',
  'mehet',
  'mekam',
  'mekës',
  'mekje',
  'mekth',
  'melcë',
  'melez',
  'melës',
  'meltë',
  'mellë',
  'memec',
  'memur',
  'mensë',
  'mentë',
  'merak',
  'mermë',
  'mesap',
  'mesëm',
  'mesës',
  'mesit',
  'mesor',
  'meste',
  'mesun',
  'meshë',
  'meshk',
  'metal',
  'metan',
  'metër',
  'metoq',
  'metro',
  'mezat',
  'ditës',
  'ditje',
  'dyzaj',
  'dhesë',
  'gojëz',
  'mëgje',
  'mëkat',
  'katar',
  'katim',
  'katoj',
  'mëkeq',
  'kequr',
  'mëkim',
  'mëkoj',
  'mëlçi',
  'lmesë',
  'lmyer',
  'llezë',
  'mëllë',
  'mëdhe',
  'mëmëz',
  'mëzim',
  'mëzoj',
  'mëngë',
  'ngojë',
  'ngore',
  'ngjes',
  'ngjër',
  'ngjis',
  'mënik',
  'mënoj',
  'nyror',
  'mënjë',
  'qakem',
  'mëqik',
  'rdhas',
  'rdhij',
  'rehem',
  'mërej',
  'rgatë',
  'rihem',
  'mërij',
  'rkosh',
  'rkurë',
  'ritës',
  'rsinë',
  'mërzi',
  'sallë',
  'simor',
  'suese',
  'suesi',
  'mësyj',
  'shere',
  'mëtim',
  'mëtoj',
  'mëzak',
  'mëzat',
  'zator',
  'zdrak',
  'zëmër',
  'mëzor',
  'micak',
  'midis',
  'miell',
  'mihës',
  'mihje',
  'mijtë',
  'mikro',
  'milak',
  'milcë',
  'milet',
  'milic',
  'milje',
  'milor',
  'minuk',
  'minus',
  'miopi',
  'miras',
  'filli',
  'mirëz',
  'miros',
  'mirrë',
  'misër',
  'rnike',
  'miskë',
  'misur',
  'mitan',
  'mitar',
  'mitër',
  'mitik',
  'mitos',
  'mitur',
  'miush',
  'mixan',
  'mixhë',
  'mizon',
  'mizor',
  'mjaft',
  'mjakë',
  'mjeke',
  'rborë',
  'rbutë',
  'rcjap',
  'mjeki',
  'mjelm',
  'mjerë',
  'mjeri',
  'mjetë',
  'mlysh',
  'mllef',
  'moçal',
  'moçëm',
  'moçim',
  'moçoj',
  'model',
  'mohaq',
  'mohës',
  'mohim',
  'mohoj',
  'mohor',
  'mokër',
  'Mokra',
  'molar',
  'molëz',
  'molis',
  'molit',
  'molos',
  'mollë',
  'monjë',
  'moral',
  'morth',
  'morrc',
  'harro',
  'motak',
  'motar',
  'motër',
  'rmëmë',
  'rtatë',
  'rzezë',
  'motës',
  'motit',
  'motiv',
  'motje',
  'motor',
  'mpiks',
  'mpirë',
  'mpitë',
  'mplak',
  'mreçë',
  'mrrol',
  'muçit',
  'mufkë',
  'muget',
  'mugët',
  'muhar',
  'mujoj',
  'mujzë',
  'mukon',
  'mulat',
  'mulla',
  'mullë',
  'mulli',
  'mumie',
  'munxë',
  'murgë',
  'murim',
  'muroj',
  'muros',
  'murrë',
  'tehem',
  'murro',
  'musha',
  'mushk',
  'llyer',
  'musht',
  'mutaf',
  'muzgë',
  'myfti',
  'myhib',
  'myhyr',
  'myjtë',
  'mykem',
  'mykëz',
  'mykje',
  'mykor',
  'mykth',
  'mykur',
  'mysët',
  'nadir',
  'naftë',
  'nakar',
  'nakël',
  'nallë',
  'namaz',
  'namus',
  'nanaç',
  'nanuq',
  'nartë',
  'natës',
  'natar',
  'natën',
  'navig',
  'nazik',
  'ndalë',
  'ndalk',
  'ndanë',
  'ndarë',
  'ndasi',
  'ndejë',
  'nderë',
  'ndesh',
  'ndezë',
  'rdymë',
  'rdysh',
  'rkohë',
  'rkrej',
  'rlidh',
  'rlikë',
  'rliks',
  'rmarr',
  'rmend',
  'rmjet',
  'rpres',
  'rsyer',
  'rsyes',
  'rshej',
  'rtesë',
  'rtuar',
  'rthur',
  'ryjor',
  'rzyes',
  'rresa',
  'shkim',
  'shkoj',
  'ndiej',
  'ndier',
  'smjek',
  'ndijë',
  'ndjej',
  'ndjek',
  'pasti',
  'ndoca',
  'ndodh',
  'ndotë',
  'ndrag',
  'ndreq',
  'imtar',
  'ndrin',
  'ndrit',
  'ndryj',
  'ndryp',
  'ndrys',
  'ndukë',
  'ndytë',
  'nefes',
  'negër',
  'nejçë',
  'nejës',
  'nejse',
  'nemte',
  'nenëz',
  'neqez',
  'nesër',
  'netër',
  'netoj',
  'nëmës',
  'nëmur',
  'nçati',
  'nçmim',
  'nçmoj',
  'ndhes',
  'nërit',
  'nfish',
  'ngrup',
  'nkrye',
  'nlloj',
  'nndaj',
  'npikë',
  'nqesh',
  'nëntë',
  'ntësh',
  'ntokë',
  'ntori',
  'nujës',
  'nujor',
  'nvete',
  'nëpër',
  'rkëmb',
  'punës',
  'ngado',
  'njyes',
  'ngajë',
  'ngaqë',
  'ngase',
  'ngath',
  'ngazë',
  'lluar',
  'ngecë',
  'ngërç',
  'rmesë',
  'ngoje',
  'ngreh',
  'nëkeq',
  'nshëm',
  'ngrij',
  'ngroh',
  'ngryk',
  'ngrys',
  'ngurr',
  'ngush',
  'ngjaj',
  'ngjak',
  'ngjan',
  'ngjas',
  'ngjat',
  'ngjet',
  'ngjir',
  'ngjiz',
  'ngjok',
  'nijet',
  'nikel',
  'nimfe',
  'nimfë',
  'ninëz',
  'nipçe',
  'nisem',
  'nismë',
  'nisur',
  'nishk',
  'nivel',
  'nizam',
  'noçkë',
  'nofkë',
  'nojmë',
  'nomad',
  'normë',
  'nosit',
  'notar',
  'noter',
  'notim',
  'notoj',
  'nuhar',
  'nuhat',
  'nullë',
  'numër',
  'rator',
  'nunël',
  'nunër',
  'nurzi',
  'nusël',
  'nusëz',
  'nuskë',
  'nyell',
  'nyjëz',
  'nyjor',
  'njaçë',
  'njaty',
  'njekë',
  'njerk',
  'anësh',
  'fazor',
  'jësim',
  'jësoj',
  'jtësi',
  'mësht',
  'mëzaj',
  'orësh',
  'polar',
  'njëri',
  'njësi',
  'sojtë',
  'njësh',
  'vetor',
  'vitmë',
  'njëzë',
  'njilë',
  'njome',
  'njomë',
  'obobo',
  'oborr',
  'ofset',
  'ojarë',
  'ojnak',
  'ojnik',
  'ojtni',
  'okare',
  'oklim',
  'oksid',
  'oktet',
  'oleve',
  'Omani',
  'omega',
  'operë',
  'opium',
  'optik',
  'oqean',
  'oreks',
  'sharë',
  'orëzi',
  'organ',
  'orgji',
  'ormis',
  'ortak',
  'ortek',
  'osakë',
  'osmak',
  'osman',
  'ostri',
  'oshtë',
  'ovore',
  'ovull',
  'oxhak',
  'paana',
  'paanë',
  'pacak',
  'avure',
  'paçka',
  'padëm',
  'padit',
  'paduk',
  'paerë',
  'pafaj',
  'pafat',
  'pafkë',
  'pafre',
  'paftë',
  'pagan',
  'pagim',
  'pagur',
  'pahir',
  'pahit',
  'paide',
  'pidhi',
  'pajim',
  'pajis',
  'pajoj',
  'pakem',
  'pakët',
  'pakëz',
  'pakoj',
  'paksa',
  'pakta',
  'ollëk',
  'palas',
  'palcë',
  'palëz',
  'palmë',
  'palok',
  'palos',
  'pallë',
  'pamiq',
  'pamor',
  'pandë',
  'panik',
  'panjë',
  'lqyer',
  'rvojë',
  'rzier',
  'paqmë',
  'paqoj',
  'parak',
  'parem',
  'ndësi',
  'parëz',
  'parim',
  'parmë',
  'parti',
  'parur',
  'pasim',
  'pasiv',
  'pasje',
  'pasmë',
  'pasoj',
  'pasor',
  'pastë',
  'pasur',
  'pasha',
  'pashë',
  'titur',
  'pashi',
  'patan',
  'patar',
  'patoj',
  'patok',
  'patos',
  'patoz',
  'paujë',
  'pauzë',
  'rtetë',
  'pazar',
  'pazot',
  'peckë',
  'pegun',
  'pehar',
  'pejan',
  'pejzë',
  'pekul',
  'pelar',
  'pelas',
  'pelin',
  'pelte',
  'pellë',
  'pellg',
  'pemël',
  'penal',
  'pendë',
  'penel',
  'penez',
  'pengë',
  'penik',
  'penoj',
  'pepeq',
  'perçe',
  'perde',
  'perlë',
  'perri',
  'pestë',
  'peshë',
  'petak',
  'petal',
  'petas',
  'petël',
  'petës',
  'shore',
  'petëz',
  'petoj',
  'petor',
  'pezëm',
  'pezul',
  'gëhem',
  'pëgëj',
  'gërje',
  'lhurë',
  'pëlyr',
  'lyrur',
  'pëlle',
  'lltum',
  'llumb',
  'rafri',
  'rbalt',
  'rball',
  'rbërë',
  'rbyth',
  'rçarë',
  'rçart',
  'rçmim',
  'rçmoj',
  'ollak',
  'udnim',
  'udnoj',
  'rdalë',
  'llesë',
  'rditë',
  'rdjeg',
  'rdhec',
  'rdhej',
  'rdhel',
  'rdhes',
  'rdhos',
  'remër',
  'rfaqe',
  'rfill',
  'rflak',
  'rflas',
  'rflet',
  'rftim',
  'rftoj',
  'rfund',
  'rgjak',
  'rgjim',
  'rgjoj',
  'rgjum',
  'rhedh',
  'rherë',
  'rhimë',
  'rhosh',
  'rjarg',
  'rjetë',
  'rkavë',
  'dheli',
  'rkore',
  'rkorë',
  'rkrah',
  'rkund',
  'fytem',
  'rligj',
  'rlind',
  'rlinë',
  'rlyej',
  'rlloç',
  'rmall',
  'rmbaj',
  'rmbas',
  'rmbyt',
  'rnatë',
  'rndaj',
  'rpall',
  'rpara',
  'rpare',
  'litem',
  'litës',
  'rpjek',
  'timor',
  'rplas',
  'rplot',
  'rposh',
  'rpush',
  'rputh',
  'rqesh',
  'rqeth',
  'përqi',
  'përse',
  'dytet',
  'dytje',
  'rsëri',
  'ritet',
  'rsiat',
  'rskaj',
  'rskuq',
  'rsysh',
  'rshaj',
  'ndosh',
  'rshij',
  'rtaci',
  'rtokë',
  'rtret',
  'rtoll',
  'rthaj',
  'përul',
  'rulem',
  'rulës',
  'rulje',
  'rulur',
  'rurim',
  'ruroj',
  'rvjel',
  'përzë',
  'rziej',
  'rrënd',
  'pësim',
  'pësoj',
  'pësor',
  'shtyj',
  'shtym',
  'piano',
  'picak',
  'picas',
  'pigme',
  'pikas',
  'pikat',
  'pikem',
  'pikël',
  'pikës',
  'synim',
  'synoj',
  'pikët',
  'takim',
  'pikëz',
  'pikim',
  'pikje',
  'pikoj',
  'pikth',
  'pikur',
  'pilaf',
  'pilcë',
  'pilog',
  'piloj',
  'pilot',
  'pille',
  'pillë',
  'pinar',
  'pincë',
  'pinok',
  'pipër',
  'pipëz',
  'pipth',
  'piqem',
  'pirat',
  'pirit',
  'pirun',
  'pirrë',
  'rëngë',
  'pistë',
  'pishk',
  'pitar',
  'pitër',
  'pitok',
  'piton',
  'pizël',
  'pizgë',
  'pjacë',
  'pjalm',
  'pjatë',
  'pjekë',
  'pjell',
  'pjerr',
  'pjesë',
  'kitës',
  'kitje',
  'plagë',
  'plakë',
  'planë',
  'plasë',
  'plate',
  'plazh',
  'plebe',
  'pleks',
  'pleme',
  'pleng',
  'plëng',
  'ngçor',
  'plima',
  'plish',
  'shtas',
  'plojë',
  'plumb',
  'plusk',
  'pllaq',
  'plluq',
  'pllaz',
  'pllum',
  'poçar',
  'erina',
  'poemë',
  'poezi',
  'pofkë',
  'pohim',
  'pohoj',
  'pohor',
  'pojak',
  'polak',
  'polem',
  'polen',
  'polic',
  'polik',
  'polkë',
  'pompë',
  'popël',
  'poroz',
  'porsa',
  'porse',
  'porsi',
  'portë',
  'porrc',
  'porre',
  'poste',
  'postë',
  'potas',
  'potir',
  'pozoj',
  'prajë',
  'pranë',
  'vënie',
  'prani',
  'prapa',
  'prapi',
  'praps',
  'praqe',
  'prask',
  'prekë',
  'prell',
  'presë',
  'presh',
  'prevë',
  'prier',
  'prift',
  'prijë',
  'prikë',
  'prime',
  'princ',
  'prind',
  'priqe',
  'prirë',
  'prish',
  'pritë',
  'prizë',
  'prokë',
  'pronë',
  'prore',
  'prozë',
  'prozh',
  'pruaj',
  'prura',
  'prush',
  'psalm',
  'psalt',
  'psiko',
  'ibabë',
  'puçik',
  'rriza',
  'pudër',
  'pufka',
  'pufkë',
  'pufte',
  'puhat',
  'pular',
  'pulëz',
  'pulis',
  'pulit',
  'pulkë',
  'pulpë',
  'pulla',
  'pullë',
  'pungë',
  'pupas',
  'lbutë',
  'pupëz',
  'puqem',
  'puqje',
  'puqur',
  'puroj',
  'purrë',
  'pusht',
  'putër',
  'pyjor',
  'pykël',
  'pykëz',
  'qafak',
  'qafas',
  'qafem',
  'shoke',
  'qafëz',
  'qafim',
  'qafoj',
  'qafok',
  'qafol',
  'qafor',
  'qafos',
  'qafuk',
  'qahem',
  'qajkë',
  'qapoj',
  'qarës',
  'qarje',
  'qartë',
  'qasem',
  'qasët',
  'qashë',
  'qatër',
  'qatip',
  'qebap',
  'qeçkë',
  'qeder',
  'qefil',
  'qefin',
  'qehën',
  'qekth',
  'qelan',
  'qelëz',
  'qelqe',
  'qemer',
  'qemër',
  'qenar',
  'qençe',
  'rikës',
  'qenëz',
  'qengj',
  'qepar',
  'qepem',
  'qepen',
  'qepër',
  'qepëz',
  'qepje',
  'qepoj',
  'qepor',
  'qepur',
  'qeras',
  'qerbë',
  'qereç',
  'qeros',
  'qerpi',
  'qerth',
  'qerre',
  'qesar',
  'qesat',
  'qeser',
  'qesim',
  'qeskë',
  'qeste',
  'qeshë',
  'qetar',
  'qetas',
  'qetës',
  'qetim',
  'qetoj',
  'qethë',
  'qezër',
  'qëkur',
  'llatë',
  'mtesë',
  'ndrak',
  'ndroj',
  'qërim',
  'qëroj',
  'qibar',
  'qibër',
  'qiell',
  'qilar',
  'qilim',
  'qimëz',
  'qiriç',
  'qitap',
  'qitem',
  'qitro',
  'qivur',
  'qofkë',
  'qofte',
  'qoftë',
  'qokaç',
  'qokël',
  'qokth',
  'qopal',
  'qorre',
  'qosem',
  'qoset',
  'qoshe',
  'qoshk',
  'quhem',
  'qukan',
  'qukem',
  'qukëç',
  'qukël',
  'qukës',
  'qukmë',
  'qurra',
  'qyfyr',
  'qylaf',
  'qylah',
  'qylyk',
  'qymez',
  'qymyr',
  'qypës',
  'qypje',
  'qyqar',
  'qyqoj',
  'qyrek',
  'qysqi',
  'qyshe',
  'qytet',
  'qytyk',
  'rabat',
  'racor',
  'radar',
  'radio',
  'radhë',
  'rajon',
  'rangë',
  'ranor',
  'rasat',
  'rasor',
  'rashë',
  'raven',
  'rebel',
  'rebus',
  'reçel',
  'regji',
  'rehat',
  'remtë',
  'rende',
  'rentë',
  'resit',
  'resoj',
  'resht',
  'retër',
  'revan',
  'rezil',
  'ndesë',
  'rënie',
  'ntgen',
  'rërët',
  'ribëj',
  'ricin',
  'rigon',
  'rihet',
  'rikan',
  'rimon',
  'rimth',
  'rinim',
  'rinoj',
  'rinor',
  'riosh',
  'ritëm',
  'rival',
  'robit',
  'robot',
  'rogaç',
  'rogja',
  'rogje',
  'rojkë',
  'rojsi',
  'romak',
  'roman',
  'romuz',
  'ronit',
  'rosak',
  'rosto',
  'rubai',
  'rubak',
  'rubël',
  'rubin',
  'ruboj',
  'rucoj',
  'rudak',
  'rufai',
  'ruhem',
  'rukoj',
  'rumun',
  'rusem',
  'Rusia',
  'ruspë',
  'rysem',
  'rysni',
  'rysur',
  'rytbe',
  'ryzoj',
  'rrabe',
  'rragë',
  'rrajë',
  'rrake',
  'rraqe',
  'rrebe',
  'rregë',
  'rrekë',
  'rremb',
  'rrenë',
  'rreng',
  'rrepë',
  'rrevë',
  'rrexë',
  'rreze',
  'rrezg',
  'rrezm',
  'fatet',
  'fehem',
  'fenjë',
  'feshk',
  'gallë',
  'gjyrë',
  'rrëke',
  'kuall',
  'kutem',
  'ngjen',
  'nxuar',
  'njinë',
  'njore',
  'pirët',
  'rrësk',
  'shajë',
  'shqas',
  'shqit',
  'vizgë',
  'rrëzë',
  'rribë',
  'rrica',
  'rricë',
  'rrikë',
  'rripa',
  'rritë',
  'rrjep',
  'rrjet',
  'rrobë',
  'rrogë',
  'teshë',
  'rrokë',
  'rrole',
  'rrome',
  'rrozg',
  'rruaj',
  'rrufe',
  'rrufë',
  'rrugë',
  'rrumb',
  'rrunë',
  'rrush',
  'rryem',
  'sabah',
  'saçmë',
  'safir',
  'sagje',
  'sahan',
  'sahat',
  'sajdi',
  'sajim',
  'sajnë',
  'sajoj',
  'sakaq',
  'sakat',
  'sakëz',
  'saksi',
  'saktë',
  'salcë',
  'saldo',
  'salep',
  'sallo',
  'samar',
  'samet',
  'samsa',
  'samtë',
  'sanëz',
  'sapun',
  'saraç',
  'saraf',
  'saraj',
  'sarëk',
  'sargi',
  'sarkë',
  'sarma',
  'saten',
  'satër',
  'savan',
  'sebep',
  'sedef',
  'sedër',
  'rfyer',
  'sefte',
  'sehir',
  'sejtë',
  'selam',
  'selit',
  'selvi',
  'semit',
  'senat',
  'senet',
  'sepal',
  'sepje',
  'sepse',
  'seroj',
  'sertë',
  'serum',
  'serrë',
  'setër',
  'sevap',
  'sevda',
  'lldis',
  'sëlli',
  'sëmur',
  'murem',
  'rithi',
  'sërma',
  'sfakë',
  'sfidë',
  'sfinë',
  'sfond',
  'sfrat',
  'sfurk',
  'sifon',
  'sigël',
  'sikur',
  'silah',
  'silic',
  'silos',
  'silur',
  'sillë',
  'sinap',
  'sinod',
  'sinus',
  'sipas',
  'sipër',
  'Siria',
  'sisor',
  'sitac',
  'sitje',
  'sitkë',
  'situr',
  'sivër',
  'sjell',
  'skape',
  'skarë',
  'skedë',
  'skelë',
  'skemë',
  'skenë',
  'skerc',
  'terrë',
  'skicë',
  'skile',
  'skili',
  'skofi',
  'skort',
  'skotë',
  'skrop',
  'skupë',
  'skutë',
  'skuth',
  'sllav',
  'smalt',
  'smirë',
  'sodit',
  'sodom',
  'sofat',
  'sofër',
  'sogje',
  'sojak',
  'sojli',
  'sokak',
  'sokol',
  'solar',
  'sondë',
  'sonet',
  'sonte',
  'sorkë',
  'sosem',
  'sosje',
  'sotëm',
  'sotme',
  'soval',
  'spahi',
  'spërk',
  'spicë',
  'spilc',
  'spinë',
  'spirë',
  'spiun',
  'splon',
  'spore',
  'sport',
  'sqimë',
  'sqoll',
  'sqotë',
  'squkë',
  'sqyre',
  'starë',
  'start',
  'stavë',
  'stazh',
  'stejë',
  'stekë',
  'stelë',
  'stemë',
  'stenë',
  'stepë',
  'stere',
  'rflok',
  'rhell',
  'rkalë',
  'rkitë',
  'rlëng',
  'rnxeh',
  'rpikë',
  'rplak',
  'rqokë',
  'rthem',
  'rvinë',
  'stihi',
  'stikë',
  'stinë',
  'stivë',
  'stoik',
  'stolë',
  'stoli',
  'strem',
  'struc',
  'strum',
  'strup',
  'stufë',
  'stuhi',
  'stuko',
  'suazë',
  'sugar',
  'suitë',
  'sulem',
  'sumak',
  'super',
  'supëz',
  'surle',
  'surmë',
  'survë',
  'susak',
  'susam',
  'susme',
  'sustë',
  'sutak',
  'suval',
  'syçkë',
  'syenë',
  'sykan',
  'sykeq',
  'sykëm',
  'sykuq',
  'synet',
  'syrëz',
  'sytar',
  'syzoj',
  'shage',
  'shahi',
  'shair',
  'shaka',
  'shakë',
  'shall',
  'shami',
  'shark',
  'shart',
  'patra',
  'sheik',
  'sheke',
  'shelg',
  'shemb',
  'shemë',
  'shend',
  'shenj',
  'sheqe',
  'sherp',
  'sherr',
  'shesh',
  'lbesë',
  'lbues',
  'mtirë',
  'njues',
  'rbesë',
  'rbyes',
  'titës',
  'titje',
  'shfaq',
  'shibë',
  'shije',
  'shinë',
  'shira',
  'shirk',
  'shist',
  'shish',
  'shitë',
  'shkak',
  'shkal',
  'zehem',
  'shkas',
  'shkel',
  'shkes',
  'shkëf',
  'lbozë',
  'lnjet',
  'lqesë',
  'lqesi',
  'lqyes',
  'purdh',
  'rdhoj',
  'shkop',
  'shkoq',
  'shkuj',
  'shkul',
  'shkyç',
  'shluk',
  'shnot',
  'shogë',
  'shokë',
  'shoku',
  'shoqe',
  'rizoj',
  'shoqi',
  'shorr',
  'shosh',
  'shotë',
  'shpan',
  'shpat',
  'shpet',
  'rbemë',
  'rdhet',
  'rgëti',
  'rngoj',
  'rngul',
  'rnjoh',
  'rthiq',
  'rthuq',
  'shpie',
  'shpif',
  'shpij',
  'shpik',
  'shpim',
  'shpoj',
  'shpor',
  'shqaz',
  'shqem',
  'shqep',
  'shqim',
  'shqip',
  'shqyt',
  'shtab',
  'shtat',
  'shteg',
  'shter',
  'piake',
  'piash',
  'shtie',
  'shtir',
  'shtog',
  'ngues',
  'shtuf',
  'shtyp',
  'shuaj',
  'shulk',
  'shuma',
  'shurr',
  'shyta',
  'shytë',
  'tabak',
  'tablo',
  'tabor',
  'tabut',
  'tafta',
  'tagër',
  'tagji',
  'tahin',
  'tajer',
  'tajgë',
  'tajit',
  'tajkë',
  'takat',
  'takëm',
  'takër',
  'takoj',
  'taksë',
  'taksi',
  'talër',
  'tallë',
  'tamah',
  'tamam',
  'tango',
  'tanin',
  'tanoj',
  'tanor',
  'tapos',
  'taraf',
  'tarbë',
  'targë',
  'tarim',
  'taroj',
  'tasuk',
  'tatim',
  'tatoj',
  'tavan',
  'tegel',
  'tehri',
  'teinë',
  'tejet',
  'tejzë',
  'tekdo',
  'teket',
  'tekqë',
  'teksa',
  'tekst',
  'tekur',
  'telef',
  'telis',
  'telje',
  'teltë',
  'telve',
  'temin',
  'tenis',
  'tenor',
  'tenjë',
  'teori',
  'tepër',
  'tepri',
  'tepsi',
  'terak',
  'terem',
  'terik',
  'terje',
  'termë',
  'termo',
  'terzi',
  'teste',
  'tetar',
  'tetor',
  'tezak',
  'tezgë',
  'tëmth',
  'tënde',
  'tëngë',
  'rbuar',
  'rësej',
  'rësor',
  'rfurk',
  'rmale',
  'rthor',
  'rrkat',
  'tifoz',
  'tigan',
  'tigër',
  'timar',
  'timon',
  'tinar',
  'tinëz',
  'tingë',
  'tipar',
  'tipik',
  'tipos',
  'tiran',
  'titan',
  'tizgë',
  'tjerë',
  'tjerr',
  'rkush',
  'rqysh',
  'tkurr',
  'tmerr',
  'toçit',
  'toger',
  'togje',
  'tokem',
  'tokël',
  'llinë',
  'tokës',
  'tokëz',
  'tonik',
  'topaz',
  'topçe',
  'topçi',
  'topil',
  'topit',
  'topuz',
  'torbë',
  'torfë',
  'torkë',
  'torno',
  'tortë',
  'torua',
  'toskë',
  'total',
  'totem',
  'tragë',
  'trakt',
  'trand',
  'trapa',
  'trase',
  'trash',
  'gimor',
  'gohem',
  'tratë',
  'trazë',
  'tredh',
  'tremb',
  'treme',
  'treni',
  'tresh',
  'tretë',
  'trevë',
  'trikë',
  'triko',
  'trill',
  'trinë',
  'trini',
  'trivë',
  'trizë',
  'trofe',
  'troke',
  'trokë',
  'truaj',
  'truar',
  'trumë',
  'trung',
  'trunt',
  'trupë',
  'trust',
  'truth',
  'trysa',
  'tuaja',
  'tubim',
  'tuboj',
  'tufan',
  'tufël',
  'tufës',
  'tufëz',
  'tufoj',
  'tuhaf',
  'tuhat',
  'tukeq',
  'tulës',
  'tulor',
  'tultë',
  'tumbë',
  'tumir',
  'tumor',
  'tumul',
  'tunel',
  'tunxh',
  'tupak',
  'tupan',
  'turbë',
  'turit',
  'turli',
  'turmë',
  'turne',
  'turra',
  'turrë',
  'tushë',
  'tutem',
  'tutël',
  'tutër',
  'tutës',
  'tutje',
  'tutkë',
  'tutor',
  'tymak',
  'tymar',
  'tymek',
  'tymoj',
  'tymos',
  'tymtë',
  'typth',
  'tyrbe',
  'thanë',
  'tharb',
  'thark',
  'tharm',
  'theke',
  'thekë',
  'theks',
  'thela',
  'thelb',
  'thelë',
  'thepa',
  'llëzë',
  'thënë',
  'rroke',
  'thias',
  'thile',
  'thinj',
  'thirr',
  'thith',
  'thiul',
  'thumb',
  'thyeç',
  'thyej',
  'thyez',
  'ububu',
  'udhak',
  'udhëz',
  'uiski',
  'ujacë',
  'ujcak',
  'ujdis',
  'ujerë',
  'ujerk',
  'ujezë',
  'ujëra',
  'ujëse',
  'ujkth',
  'ujore',
  'ujqer',
  'ujtis',
  'uloke',
  'ultas',
  'ultra',
  'ulluk',
  'unaza',
  'unazë',
  'uratë',
  'urban',
  'ratës',
  'urezë',
  'urgus',
  'urith',
  'urtar',
  'urtoj',
  'uruar',
  'urues',
  'urrej',
  'ushtë',
  'ushuj',
  'utopi',
  'utrin',
  'uzinë',
  'uzurë',
  'vadit',
  'vadhë',
  'vagon',
  'vajës',
  'vajim',
  'vajoj',
  'vajor',
  'vajos',
  'vajzë',
  'vakëf',
  'vakër',
  'vakët',
  'vakje',
  'vakoj',
  'vakur',
  'valët',
  'vitet',
  'vitës',
  'vitje',
  'valim',
  'valoj',
  'valor',
  'valle',
  'vangë',
  'vapem',
  'vapët',
  'vapim',
  'vapje',
  'vapoj',
  'vapor',
  'varak',
  'varda',
  'vargj',
  'varje',
  'varkë',
  'varrë',
  'vasal',
  'vaskë',
  'vashë',
  'vatan',
  'vatër',
  'vator',
  'vathe',
  'vathë',
  'veçan',
  'veças',
  'veçim',
  'veçoj',
  'veçor',
  'veçqë',
  'veçse',
  'vedër',
  'vegël',
  'vegim',
  'vegoj',
  'vegsh',
  'vegjë',
  'vejan',
  'velem',
  'velet',
  'velët',
  'velëz',
  'velur',
  'vello',
  'vemëz',
  'vemje',
  'vençë',
  'vende',
  'vendi',
  'venit',
  'venom',
  'venjë',
  'vepër',
  'veqil',
  'verak',
  'verem',
  'vergë',
  'verim',
  'veroj',
  'veror',
  'verzë',
  'verri',
  'vesim',
  'vesit',
  'vesoj',
  'vetëm',
  'timtë',
  'vetiu',
  'vetmi',
  'veton',
  'vezir',
  'vezme',
  'vezor',
  'llami',
  'llath',
  'llazi',
  'ngëri',
  'ngërt',
  'ngosh',
  'vërej',
  'vërsë',
  'rsnik',
  'rshoj',
  'vërri',
  'shnak',
  'vëthe',
  'zhgim',
  'zhgoj',
  'vickë',
  'viçar',
  'viçok',
  'vidan',
  'vidër',
  'vidra',
  'vidul',
  'vidhë',
  'vigan',
  'vigël',
  'vigmë',
  'vigje',
  'vihem',
  'vijëz',
  'vijim',
  'vijoj',
  'vijor',
  'vijos',
  'vikat',
  'vilar',
  'vilat',
  'vilet',
  'vilez',
  'elist',
  'viran',
  'virok',
  'virua',
  'virus',
  'visar',
  'viskë',
  'vishë',
  'vithe',
  'vivar',
  'vizak',
  'vizat',
  'vizim',
  'vizoj',
  'vizhë',
  'vjedh',
  'vjegë',
  'vjela',
  'vjelë',
  'vjell',
  'vlagë',
  'vlerë',
  'vlesë',
  'Vlora',
  'vluar',
  'vllah',
  'vodkë',
  'loshe',
  'lushe',
  'vokal',
  'volit',
  'voltë',
  'vonim',
  'vonoj',
  'vorbë',
  'votoj',
  'vozit',
  'vtaçi',
  'vragë',
  'vrajë',
  'vrasë',
  'vrasi',
  'vrevë',
  'vrima',
  'vrimë',
  'vring',
  'vrull',
  'vuhet',
  'vulër',
  'vulos',
  'vulla',
  'vushë',
  'vyshk',
  'xanxë',
  'xëgim',
  'xëgit',
  'gitem',
  'xëgoj',
  'xixon',
  'xhaba',
  'xhade',
  'xhadi',
  'xhajë',
  'xhami',
  'xhane',
  'xhaul',
  'xhave',
  'xheku',
  'xhela',
  'xherr',
  'xheza',
  'xhikë',
  'xhiko',
  'xhilë',
  'xhind',
  'rrima',
  'xhins',
  'xhiro',
  'xhokë',
  'xhore',
  'xhuli',
  'xhuma',
  'xhura',
  'xhuxh',
  'xhybe',
  'yçkël',
  'yjësi',
  'ylber',
  'ylefe',
  'yllës',
  'yllkë',
  'yllth',
  'yrnek',
  'yrysh',
  'yrrja',
  'yshyr',
  'yvell',
  'zabel',
  'zabit',
  'zabua',
  'zagar',
  'zagën',
  'zakon',
  'zakum',
  'zalis',
  'zaman',
  'zamkë',
  'zanat',
  'zanon',
  'zarar',
  'zarbë',
  'zaten',
  'zatet',
  'zavëz',
  'zbath',
  'zbejt',
  'rdhul',
  'zblok',
  'zbraz',
  'zbres',
  'zbruj',
  'zbrum',
  'zbyll',
  'zbyth',
  'zdati',
  'zdatk',
  'zdraj',
  'malaq',
  'nkthi',
  'zdrit',
  'zdrug',
  'zdryp',
  'zebër',
  'zefir',
  'zegal',
  'zegël',
  'zeher',
  'zekth',
  'zemër',
  'rartë',
  'rluan',
  'rmirë',
  'rpulë',
  'zenit',
  'zerde',
  'zerva',
  'zerre',
  'zeshk',
  'zezak',
  'zezkë',
  'zezoj',
  'mbicë',
  'mëroj',
  'zënës',
  'zënie',
  'zënkë',
  'zëroj',
  'zgale',
  'zgrap',
  'zgrip',
  'zgrof',
  'zgrop',
  'zgjat',
  'zgjiç',
  'zgjim',
  'zgjoj',
  'zgjua',
  'zigur',
  'zihem',
  'zihet',
  'zijos',
  'zikeq',
  'zilar',
  'zilis',
  'zjarr',
  'zogël',
  'zogth',
  'zokth',
  'zonak',
  'zonal',
  'zonja',
  'zorim',
  'zorit',
  'zorrë',
  'zotem',
  'zotim',
  'zotoj',
  'zukat',
  'zulmë',
  'zurna',
  'zuskë',
  'zushë',
  'zuzar',
  'zvarg',
  'titem',
  'zverk',
  'zymti',
  'rdinë',
  'zhake',
  'zhakë',
  'llicë',
  'zhapë',
  'zhapi',
  'zharg',
  'zhbëj',
  'zhbij',
  'zhdeh',
  'zhdëp',
  'rjend',
  'zhduk',
  'zhele',
  'zhgan',
  'njyer',
  'zhgun',
  'zhile',
  'zhivë',
  'zhugë',
  'zhukë',
  'zhurk',
  'zhvar',
  'zhvat',
  'zhyej',
  'zhyer',
  'zhymë',
  'truri',
  'trupi',
  'veshi',
  'abdes',
  'abuli',
  'adept',
  'afaki',
  'afazi',
  'afekt',
  'aferë',
  'afiks',
  'afoni',
  'afshi',
  'agami',
  'agavë',
  'agora',
  'agrum',
  'ahmak',
  'ajran',
  'akant',
  'akili',
  'akoli',
  'aktin',
  'albin',
  'albit',
  'aline',
  'alize',
  'allaj',
  'aluat',
  'ambar',
  'ambër',
  'anuri',
  'apeks',
  'apnoe',
  'areal',
  'argon',
  'artel',
  'arter',
  'AShAK',
  'AShSh',
  'astat',
  'Atenë',
  'athje',
  'Atilë',
  'atoni',
  'audio',
  'aveny',
  'axhio',
  'azgen',
  'bakam',
  'barbë',
  'barel',
  'barit',
  'barot',
  'barre',
  'baske',
  'basti',
  'batec',
  'batuk',
  'bekri',
  'berat',
  'beril',
  'bërki',
  'bibël',
  'biber',
  'bidat',
  'bifkë',
  'bingo',
  'birov',
  'blude',
  'bludë',
  'bocel',
  'bohem',
  'bojar',
  'boksë',
  'bolid',
  'borat',
  'bordo',
  'borsë',
  'borsh',
  'bozgë',
  'bracë',
  'braki',
  'brazë',
  'breki',
  'bremë',
  'brigë',
  'brixh',
  'bronc',
  'brosh',
  'brunc',
  'brunx',
  'BSPSh',
  'bufer',
  'bugaz',
  'bukli',
  'burgi',
  'butan',
  'buton',
  'butur',
  'çagjë',
  'çajkë',
  'çajre',
  'capin',
  'çatmë',
  'cedim',
  'çejnë',
  'cerar',
  'çeren',
  'çerve',
  'çetik',
  'Cezar',
  'cicar',
  'çiçek',
  'çinar',
  'çipçi',
  'citër',
  'çizel',
  'çoçek',
  'çomak',
  'cunam',
  'çuran',
  'çutër',
  'çyçek',
  'dakik',
  'dalie',
  'dandi',
  'dativ',
  'dekik',
  'dëmës',
  'dermë',
  'desen',
  'detaj',
  'dezen',
  'dimër',
  'domuz',
  'donor',
  'draft',
  'dresh',
  'edikt',
  'efete',
  'ekses',
  'eksod',
  'enjte',
  'eocen',
  'eolit',
  'errur',
  'espap',
  'eston',
  'etazh',
  'etezi',
  'etnos',
  'eupne',
  'evlia',
  'falës',
  'falur',
  'farad',
  'faull',
  'femur',
  'fenol',
  'fetus',
  'fibul',
  'fikje',
  'fikus',
  'filik',
  'fjeld',
  'flegë',
  'fletë',
  'flint',
  'flirt',
  'fluks',
  'Fobos',
  'fokus',
  'frenë',
  'fryjë',
  'FShMN',
  'gabel',
  'gabro',
  'gagat',
  'gajbë',
  'gajës',
  'galan',
  'Galit',
  'galon',
  'gamen',
  'ganec',
  'gangë',
  'gargë',
  'gavan',
  'gavun',
  'gazdë',
  'gazim',
  'gepek',
  'gibon',
  'gilcë',
  'gingë',
  'ginko',
  'gjele',
  'gjiri',
  'glajd',
  'glanc',
  'glans',
  'glepë',
  'gliqe',
  'gllup',
  'glosë',
  'glotë',
  'glyrë',
  'gneis',
  'gnomë',
  'gnozë',
  'gojcë',
  'gomën',
  'gomim',
  'gonit',
  'gopak',
  'goren',
  'gorgë',
  'goruc',
  'grahe',
  'gramë',
  'granë',
  'gremë',
  'grepç',
  'grerë',
  'grilë',
  'grimë',
  'grisë',
  'grizu',
  'gruar',
  'gruzë',
  'guano',
  'guazë',
  'gubëz',
  'gudok',
  'gufar',
  'gufër',
  'gufim',
  'gufkë',
  'gulçi',
  'gunar',
  'gunëz',
  'gurth',
  'gusht',
  'gypth',
  'hakoç',
  'halës',
  'haman',
  'hamje',
  'hanak',
  'hasje',
  'hedhe',
  'henec',
  'heqël',
  'heqim',
  'hilus',
  'himen',
  'hipës',
  'horok',
  'hundç',
  'hural',
  'indic',
  'infuz',
  'INIMA',
  'INSIG',
  'irade',
  'jumak',
  'kaçik',
  'kazaz',
  'kazus',
  'keson',
  'kibuc',
  'kimus',
  'kiper',
  'kodim',
  'koine',
  'kolor',
  'konkë',
  'konto',
  'korzo',
  'kramp',
  'kreol',
  'kryes',
  'kulak',
  'kunup',
  'kuran',
  'kuvet',
  'kyçës',
  'lafet',
  'laser',
  'legat',
  'lëmin',
  'lemur',
  'lentë',
  'lepër',
  'lider',
  'livër',
  'lizol',
  'lloçë',
  'lokus',
  'loran',
  'luntë',
  'lupus',
  'lutec',
  'lypur',
  'mafie',
  'magot',
  'mamut',
  'manan',
  'manit',
  'manoz',
  'mbiun',
  'mecen',
  'megom',
  'melos',
  'metil',
  'metis',
  'metoh',
  'mezon',
  'miazë',
  'micel',
  'mider',
  'mikth',
  'miozë',
  'modem',
  'modul',
  'modus',
  'moket',
  'moler',
  'monom',
  'monoz',
  'moped',
  'motel',
  'motet',
  'mufël',
  'mukat',
  'musaf',
  'namuz',
  'neper',
  'ngulm',
  'niton',
  'notes',
  'nxënë',
  'obati',
  'odeon',
  'odise',
  'ofrim',
  'ogiçe',
  'Okean',
  'okser',
  'oktan',
  'oktav',
  'oleat',
  'oleum',
  'olivë',
  'olluk',
  'oltar',
  'onani',
  'oniki',
  'oniks',
  'opati',
  'orden',
  'ordhi',
  'oreol',
  'Orhan',
  'Orion',
  'orkan',
  'orkit',
  'orman',
  'oselë',
  'oshaf',
  'oskar',
  'ostit',
  'ozenë',
  'palad',
  'panel',
  'papaq',
  'papat',
  'paraf',
  'paret',
  'pasus',
  'patër',
  'pedal',
  'Pegaz',
  'pemzë',
  'penis',
  'pepit',
  'përce',
  'peron',
  'petit',
  'pilon',
  'pilor',
  'pinen',
  'pirop',
  'plint',
  'plise',
  'poete',
  'poker',
  'polip',
  'pozim',
  'premi',
  'preri',
  'prill',
  'psike',
  'ptozë',
  'punkt',
  'putir',
  'qafir',
  'qasje',
  'qukth',
  'qyski',
  'rabin',
  'radon',
  'rafal',
  'ragbi',
  'rahat',
  'rakit',
  'rakun',
  'rampë',
  'rapir',
  'raund',
  'reces',
  'rehen',
  'rejon',
  'reket',
  'rerën',
  'resho',
  'resor',
  'resto',
  'resul',
  'retor',
  'retur',
  'reumë',
  'rever',
  'rezon',
  'rezus',
  'rigël',
  'rinit',
  'risim',
  'riter',
  'rixha',
  'rizik',
  'robti',
  'rondo',
  'rospi',
  'rotor',
  'RPSSh',
  'rubid',
  'rufet',
  'rulet',
  'rulim',
  'rulot',
  'rumbë',
  'rundë',
  'ruten',
  'rutil',
  'sabër',
  'sahel',
  'salto',
  'samit',
  'sarek',
  'SEATO',
  'selen',
  'serbe',
  'serir',
  'seshë',
  'sezon',
  'shans',
  'shefe',
  'ShISh',
  'sidër',
  'Silen',
  'silon',
  'simun',
  'sitka',
  'skrap',
  'skrëb',
  'skuqe',
  'splic',
  'strat',
  'stres',
  'supin',
  'syfyr',
  'syqen',
  'taban',
  'tagmë',
  'talir',
  'talon',
  'talus',
  'tapet',
  'tarak',
  'taroç',
  'tarok',
  'teget',
  'tekno',
  'telur',
  'tepih',
  'tercë',
  'terme',
  'tiarë',
  'tigon',
  'tildë',
  'tjera',
  'tmezë',
  'toner',
  'toron',
  'torzo',
  'trake',
  'trans',
  'tremë',
  'trend',
  'trial',
  'trier',
  'tromb',
  'tuber',
  'tubet',
  'turke',
  'tvang',
  'tvist',
  'UÇPMB',
  'ulëse',
  'Uliks',
  'union',
  'UNMIK',
  'urinë',
  'uvalë',
  'uvulë',
  'uzbek',
  'vakum',
  'valer',
  'vanad',
  'velar',
  'velej',
  'venet',
  'vespë',
  'vestë',
  'video',
  'vikar',
  'violë',
  'viril',
  'viroz',
  'vizir',
  'volan',
  'volum',
  'vraçi',
  'vulvë',
  'xhudo',
  'yshur',
  'zambo',
  'zapar',
  'zelot',
  'zigot',
  'zijan',
  'zllot',
  'zmalt',
  'kodik',
  'topth',
  'kurvë',
  'kyçje',
  'abaje',
  'abaku',
  'abati',
  'abeje',
  'abeji',
  'abisi',
  'abusi',
  'abuzi',
  'acidi',
  'adeti',
  'aduti',
  'afati',
  'afeli',
  'afere',
  'afrie',
  'aftre',
  'agari',
  'agati',
  'agimi',
  'agmie',
  'agori',
  'ahuri',
  'ajari',
  'ajëzi',
  'ajrie',
  'ajthi',
  'akçiu',
  'Akili',
  'akshi',
  'akuze',
  'albre',
  'aleje',
  'alemi',
  'aleti',
  'allci',
  'ambri',
  'ameje',
  'amebe',
  'ameli',
  'amëze',
  'amidi',
  'amili',
  'amoni',
  'amtie',
  'anaku',
  'anasi',
  'aneje',
  'anese',
  'animi',
  'anode',
  'anusi',
  'aorte',
  'AP-je',
  'apeli',
  'arabi',
  'arapi',
  'arari',
  'arbri',
  'arene',
  'arëze',
  'arnie',
  'arome',
  'arrçi',
  'ashre',
  'ashri',
  'ashku',
  'ashke',
  'ashti',
  'ashte',
  'askie',
  'aspre',
  'astme',
  'ataku',
  'Atene',
  'Atile',
  'atoli',
  'atomi',
  'atuje',
  'avali',
  'avazi',
  'avisi',
  'azhde',
  'azili',
  'azoti',
  'babai',
  'backe',
  'badre',
  'bafre',
  'bafti',
  'bagmi',
  'bahti',
  'bajge',
  'bajti',
  'bajze',
  'bakri',
  'balçi',
  'balte',
  'bande',
  'banje',
  'banke',
  'barbe',
  'bardi',
  'bariu',
  'barku',
  'barme',
  'bashi',
  'basku',
  'basme',
  'bathe',
  'baxhe',
  'BE-je',
  'bejku',
  'bejke',
  'belce',
  'belgu',
  'bendi',
  'bengu',
  'berku',
  'berri',
  'bezge',
  'bezhe',
  'bible',
  'bifke',
  'bigle',
  'bikre',
  'bimle',
  'bimse',
  'bindi',
  'birre',
  'bishe',
  'bisku',
  'biske',
  'bixhe',
  'bizge',
  'blace',
  'blane',
  'blate',
  'blege',
  'blete',
  'blici',
  'blide',
  'blofi',
  'bloje',
  'bloze',
  'bluje',
  'bluzi',
  'bluze',
  'boble',
  'boçke',
  'boene',
  'boeri',
  'bogle',
  'bokle',
  'boksi',
  'bokse',
  'bolbe',
  'bolle',
  'bombe',
  'bonje',
  'bonzi',
  'bordi',
  'borie',
  'borme',
  'borse',
  'bosku',
  'boske',
  'bozge',
  'braçi',
  'brace',
  'braje',
  'braku',
  'brami',
  'brane',
  'brave',
  'braze',
  'bregu',
  'breje',
  'breme',
  'brene',
  'brese',
  'breve',
  'brezi',
  'briri',
  'brice',
  'brige',
  'brime',
  'brise',
  'brize',
  'broke',
  'bromi',
  'bruçi',
  'bruke',
  'brumi',
  'bruzi',
  'buace',
  'buble',
  'bufke',
  'buhie',
  'bujku',
  'bukle',
  'bukie',
  'bulbi',
  'bulge',
  'bulle',
  'bumbe',
  'bunde',
  'bungu',
  'bunge',
  'burgu',
  'burie',
  'burme',
  'burse',
  'bushi',
  'busti',
  'butri',
  'buzle',
  'buzmi',
  'bythe',
  'cacri',
  'çaçke',
  'çadre',
  'çafri',
  'çafke',
  'çagje',
  'çairi',
  'cajke',
  'çajke',
  'çakle',
  'çakre',
  'çakie',
  'çalie',
  'çalle',
  'çamre',
  'cange',
  'çange',
  'çante',
  'çapoi',
  'çarku',
  'çarti',
  'çarte',
  'çasti',
  'çatie',
  'çatme',
  'CD-je',
  'cedri',
  'cefle',
  'çefke',
  'cegmi',
  'çejne',
  'çejzi',
  'çekie',
  'cenge',
  'censi',
  'cerge',
  'cërke',
  'cerme',
  'cërri',
  'çerri',
  'çezme',
  'ciani',
  'cible',
  'cifle',
  'çifti',
  'cikle',
  'cikli',
  'çikle',
  'cikme',
  'cikne',
  'cimbi',
  'çimke',
  'cinge',
  'çinie',
  'cinke',
  'çipsi',
  'cirku',
  'cirke',
  'cirle',
  'ciroi',
  'cisti',
  'citre',
  'çivie',
  'cjapi',
  'çmese',
  'çmimi',
  'çoçri',
  'cokle',
  'çorbe',
  'corke',
  'croge',
  'çudie',
  'çuesi',
  'cufle',
  'çufre',
  'cukle',
  'çukle',
  'culli',
  'çulli',
  'culle',
  'çulze',
  'cumri',
  'cungu',
  'cunge',
  'çurgu',
  'çurke',
  'curri',
  'çutre',
  'CV-je',
  'dacke',
  'dalte',
  'damke',
  'dange',
  'darke',
  'dashi',
  'dasie',
  'dasme',
  'debie',
  'dejçe',
  'dejke',
  'deliu',
  'delli',
  'delte',
  'dengu',
  'derme',
  'derri',
  'derti',
  'dh-je',
  'dhëne',
  'dhëni',
  'dhesi',
  'dhoge',
  'dhome',
  'dhune',
  'dible',
  'diele',
  'diete',
  'diezi',
  'dimri',
  'dinie',
  'diode',
  'disku',
  'dizge',
  'djali',
  'djege',
  'djepi',
  'dobie',
  'docke',
  'doçke',
  'dogme',
  'domke',
  'doriu',
  'drage',
  'drame',
  'drapi',
  'dreri',
  'drege',
  'dreke',
  'dreni',
  'drene',
  'dreqi',
  'dresi',
  'drite',
  'drize',
  'droge',
  'droje',
  'dromi',
  'dropi',
  'druge',
  'drumi',
  'dryni',
  'duaje',
  'dudie',
  'dueli',
  'dueti',
  'duhie',
  'duhme',
  'dujke',
  'dumbi',
  'dumke',
  'dunge',
  'dushi',
  'dylli',
  'dyshi',
  'dysie',
  'ecure',
  'edemi',
  'efshi',
  'egjre',
  'egoje',
  'ekipi',
  'elani',
  'elati',
  'elite',
  'emali',
  'ëmbli',
  'ëmble',
  'emiri',
  'epiku',
  'epike',
  'epodi',
  'epoke',
  'eposi',
  'epshi',
  'eraku',
  'erari',
  'eseje',
  'esesi',
  'eshku',
  'eshke',
  'esivi',
  'etani',
  'etape',
  'eteri',
  'etike',
  'etili',
  'etnie',
  'etydi',
  'ezani',
  'faizi',
  'fajke',
  'faksi',
  'fakti',
  'falli',
  'falme',
  'fansi',
  'fanti',
  'farke',
  'farse',
  'fashe',
  'fatie',
  'faune',
  'feçke',
  'fejze',
  'femre',
  'fende',
  'fëngu',
  'ferie',
  'ferme',
  'ferri',
  'ferre',
  'feudi',
  'fibre',
  'fieri',
  'filmi',
  'finje',
  'firme',
  'firni',
  'fjale',
  'flake',
  'flame',
  'flege',
  'flete',
  'flije',
  'fliqi',
  'fliti',
  'floku',
  'flore',
  'flote',
  'fluge',
  'foksi',
  'folle',
  'fondi',
  'fonti',
  'force',
  'forme',
  'fraku',
  'fraqi',
  'frati',
  'fraze',
  'freri',
  'freni',
  'frene',
  'freze',
  'frike',
  'froni',
  'frori',
  'frugu',
  'fruqi',
  'frute',
  'fryje',
  'fryme',
  'fryre',
  'fryti',
  'fryze',
  'ftesi',
  'ftese',
  'ftuje',
  'fuçie',
  'fundi',
  'funti',
  'fuqie',
  'furçe',
  'furie',
  'furke',
  'furre',
  'fushe',
  'fuste',
  'fyesi',
  'fyrle',
  'gaçke',
  'gajbe',
  'gajge',
  'galci',
  'gandi',
  'gange',
  'garbe',
  'gardi',
  'garde',
  'garge',
  'garze',
  'gatre',
  'gathi',
  'gauçi',
  'gauri',
  'gausi',
  'gavre',
  'gazde',
  'gërbi',
  'germe',
  'gilce',
  'ginge',
  'gipsi',
  'gj-je',
  'gjahu',
  'gjaku',
  'gjami',
  'gjane',
  'gjase',
  'gjaze',
  'gjëje',
  'gjëri',
  'gjeli',
  'gjëme',
  'gjepi',
  'gjeqi',
  'gjëze',
  'gjize',
  'gjobe',
  'gjoci',
  'gjoku',
  'gjoli',
  'gjoni',
  'gjori',
  'gjuri',
  'gjuhe',
  'gjumi',
  'gjyçi',
  'gjymi',
  'gjyqi',
  'glase',
  'glepe',
  'glete',
  'glifi',
  'gline',
  'globi',
  'gloqi',
  'glose',
  'glote',
  'glyre',
  'gnomi',
  'gnome',
  'gnoze',
  'gnuje',
  'godie',
  'gogle',
  'gojce',
  'golfi',
  'gomne',
  'gongu',
  'gopçi',
  'gorge',
  'goshe',
  'grace',
  'grade',
  'grami',
  'grame',
  'grane',
  'grati',
  'greci',
  'greku',
  'greme',
  'greni',
  'grepi',
  'grere',
  'grese',
  'greve',
  'gribe',
  'grife',
  'grihe',
  'grile',
  'grimi',
  'grime',
  'gripi',
  'grise',
  'grizi',
  'groje',
  'grofi',
  'grogu',
  'grope',
  'gruçi',
  'grupi',
  'gruri',
  'gruze',
  'gryke',
  'guace',
  'guaze',
  'gufre',
  'gufke',
  'gulfe',
  'gulmi',
  'gunge',
  'gurdi',
  'gurre',
  'gushe',
  'habie',
  'hairi',
  'halle',
  'hapsi',
  'harci',
  'harçi',
  'harku',
  'harpe',
  'harre',
  'harte',
  'hasre',
  'hasmi',
  'hauri',
  'hauzi',
  'havie',
  'hedie',
  'hejde',
  'hejze',
  'helli',
  'helmi',
  'heqle',
  'herci',
  'heroi',
  'herri',
  'hiati',
  'hidre',
  'hiene',
  'himni',
  'hinke',
  'hipie',
  'hirre',
  'hithi',
  'hitie',
  'honie',
  'horri',
  'hosie',
  'hoxhe',
  'huaje',
  'huaji',
  'huasi',
  'hubie',
  'humbe',
  'hurbe',
  'hurme',
  'hyrie',
  'ickle',
  'ideje',
  'idhce',
  'idili',
  'ikësi',
  'ikone',
  'ikuri',
  'ilaçi',
  'ileti',
  'iliku',
  'iliri',
  'ilnje',
  'imaku',
  'imami',
  'imani',
  'imtie',
  'inati',
  'IOM-i',
  'irati',
  'iride',
  'iriqi',
  'irize',
  'isoje',
  'istmi',
  'jahti',
  'jakie',
  'jambi',
  'japie',
  'jardi',
  'jarge',
  'jermi',
  'jevgu',
  'jevge',
  'joshe',
  'juani',
  'jufke',
  'junte',
  'jurie',
  'kacie',
  'kaçie',
  'kaçke',
  'kadhe',
  'kadiu',
  'kafke',
  'kajle',
  'kakie',
  'kalku',
  'kampi',
  'kaosi',
  'kapie',
  'kapti',
  'kapte',
  'karai',
  'karke',
  'karme',
  'karpe',
  'karte',
  'kaske',
  'kasti',
  'kaste',
  'kathi',
  'katoi',
  'kauçi',
  'kauri',
  'kauze',
  'kazme',
  'kedhi',
  'KEK-u',
  'keksi',
  'këmbe',
  'këndi',
  'kënde',
  'kënge',
  'kepce',
  'kërme',
  'kërpi',
  'kerri',
  'kërri',
  'kësti',
  'ketri',
  'kikle',
  'kille',
  'kilze',
  'kimie',
  'kindi',
  'kipci',
  'kishe',
  'kjome',
  'klani',
  'klase',
  'kleke',
  'kleri',
  'kliku',
  'klike',
  'klime',
  'klite',
  'kloni',
  'klori',
  'klubi',
  'kmeri',
  'kmese',
  'kobre',
  'koçie',
  'kocke',
  'kodre',
  'kojke',
  'kokle',
  'koksi',
  'kolli',
  'kolle',
  'kombi',
  'komçe',
  'KON-i',
  'konke',
  'konti',
  'kopre',
  'kopse',
  'korbi',
  'korde',
  'korie',
  'koshi',
  'krahu',
  'krani',
  'krapi',
  'krasi',
  'krece',
  'kreke',
  'kremi',
  'krepi',
  'krese',
  'kreve',
  'krife',
  'kriku',
  'krimi',
  'kripi',
  'kripe',
  'krise',
  'krize',
  'kroce',
  'kroli',
  'kromi',
  'krome',
  'krosi',
  'krose',
  'krupi',
  'krupe',
  'kryqi',
  'KS-je',
  'kuace',
  'kuble',
  'kucke',
  'kuçke',
  'kudre',
  'kudhi',
  'kufke',
  'kulli',
  'kulle',
  'kulmi',
  'kulti',
  'kumbi',
  'kumie',
  'kumti',
  'kunge',
  'kunji',
  'kuote',
  'kuqle',
  'kurdi',
  'kurmi',
  'kursi',
  'kusie',
  'kutie',
  'kyriu',
  'lacre',
  'laçke',
  'laiku',
  'lajke',
  'lajmi',
  'lakeu',
  'lakre',
  'lapre',
  'lapsi',
  'larie',
  'laroi',
  'larve',
  'laure',
  'lavre',
  'lebre',
  'lecke',
  'ledhi',
  'ledhe',
  'lehti',
  'lekne',
  'lëkoi',
  'lemze',
  'lënde',
  'lëngu',
  'lënge',
  'lenie',
  'lente',
  'lepre',
  'leshi',
  'letre',
  'liasi',
  'libri',
  'liceu',
  'lifti',
  'ligti',
  'ligji',
  'limbe',
  'limfe',
  'linçi',
  'lindi',
  'lingu',
  'linge',
  'linje',
  'lirie',
  'lisne',
  'lisme',
  'liste',
  'litre',
  'litri',
  'livre',
  'll-je',
  'llaci',
  'llaçi',
  'llace',
  'llafi',
  'llaje',
  'llaku',
  'llake',
  'llape',
  'llase',
  'llave',
  'llëre',
  'llobi',
  'lloçi',
  'lloçe',
  'llofi',
  'llohe',
  'lloji',
  'llomi',
  'llozi',
  'lluce',
  'lluke',
  'llumi',
  'llume',
  'llupe',
  'lluqi',
  'loçke',
  'lodre',
  'lojbe',
  'lolie',
  'lordi',
  'loshi',
  'lozhe',
  'luani',
  'lubie',
  'lufte',
  'lukre',
  'luksi',
  'lunge',
  'lunte',
  'lurie',
  'lurke',
  'lushi',
  'luspe',
  'luzme',
  'lyesi',
  'lythi',
  'maçke',
  'madhi',
  'mafre',
  'magme',
  'mahie',
  'majmi',
  'majte',
  'makre',
  'malli',
  'malmi',
  'mamie',
  'mange',
  'manie',
  'manje',
  'marke',
  'marsi',
  'marte',
  'mashe',
  'maske',
  'matke',
  'maune',
  'mauri',
  'mavie',
  'mazie',
  'maziu',
  'mbare',
  'mbari',
  'mbese',
  'meçke',
  'meiti',
  'melce',
  'melle',
  'mënge',
  'menje',
  'mënje',
  'mense',
  'mente',
  'mërçi',
  'mërie',
  'meshe',
  'metri',
  'miaze',
  'mijte',
  'milce',
  'milli',
  'miomi',
  'mioze',
  'misri',
  'mishi',
  'miske',
  'mitre',
  'mixhe',
  'mjake',
  'mjeku',
  'mjeti',
  'mjete',
  'moçmi',
  'mokre',
  'molle',
  'monje',
  'morfi',
  'morie',
  'morri',
  'morsi',
  'morti',
  'moshe',
  'motre',
  'mreçe',
  'mreti',
  'mrizi',
  'muaji',
  'mufle',
  'mufke',
  'mujze',
  'mulle',
  'mundi',
  'munku',
  'munxe',
  'murgu',
  'murge',
  'murri',
  'murre',
  'muzeu',
  'muzgu',
  'muzge',
  'mylku',
  'myrgu',
  'nafte',
  'naivi',
  'naksi',
  'nalle',
  'ndale',
  'ndare',
  'ndeje',
  'nderi',
  'ndeze',
  'ndije',
  'ndoti',
  'nduke',
  'ndyti',
  'negri',
  'nejçe',
  'neoni',
  'nepsi',
  'nervi',
  'netre',
  'nethi',
  'ngaje',
  'ngeje',
  'ngece',
  'ngeli',
  'nguci',
  'nguli',
  'nguti',
  'niobi',
  'nisie',
  'nisme',
  'njaçe',
  'njeke',
  'njile',
  'noçke',
  'nofke',
  'nojme',
  'norme',
  'notie',
  'nulle',
  'numri',
  'nunle',
  'nunre',
  'nusle',
  'nuske',
  'nxëni',
  'nxiri',
  'nxiti',
  'oboli',
  'obusi',
  'odeje',
  'ofiqi',
  'ofisi',
  'ofshi',
  'ogiçi',
  'oguri',
  'ojare',
  'okapi',
  'okari',
  'okësi',
  'oleri',
  'olive',
  'omani',
  'opali',
  'opere',
  'opusi',
  'orari',
  'Orfeu',
  'orizi',
  'ormie',
  'oroku',
  'orumi',
  'osake',
  'osele',
  'oshte',
  'otiti',
  'ovali',
  'ovuli',
  'ozene',
  'ozoni',
  'padie',
  'pafeu',
  'pafke',
  'pafte',
  'pahie',
  'pakti',
  'pakte',
  'palce',
  'palli',
  'palle',
  'palme',
  'pande',
  'panje',
  'paori',
  'PAP-i',
  'parie',
  'parku',
  'parme',
  'pashe',
  'pasie',
  'paste',
  'patri',
  'pauze',
  'pazhi',
  'pazie',
  'PD-je',
  'pecke',
  'pejze',
  'pelle',
  'pemle',
  'pemze',
  'pende',
  'pengu',
  'penge',
  'pensi',
  'peoni',
  'përçi',
  'perle',
  'persi',
  'peshe',
  'peste',
  'pesti',
  'petle',
  'pethi',
  'petku',
  'pezmi',
  'pikle',
  'pilce',
  'pince',
  'pioni',
  'pipre',
  'pirgu',
  'pirie',
  'pirre',
  'pishe',
  'pisku',
  'piske',
  'piste',
  'pitre',
  'pizle',
  'pizge',
  'pjace',
  'pjate',
  'pjeku',
  'pjeke',
  'pjese',
  'plafi',
  'plage',
  'plaku',
  'plake',
  'plani',
  'plane',
  'plase',
  'plehu',
  'plepi',
  'plimi',
  'pliqi',
  'plisi',
  'ploje',
  'plori',
  'plugu',
  'plusi',
  'poeme',
  'poeni',
  'poeti',
  'pofke',
  'polke',
  'pompe',
  'ponçi',
  'ponti',
  'pople',
  'porti',
  'porte',
  'posti',
  'PR-je',
  'pragu',
  'praje',
  'prape',
  'prasi',
  'preje',
  'preke',
  'prere',
  'prese',
  'preve',
  'prije',
  'priku',
  'prike',
  'prire',
  'prite',
  'prize',
  'progu',
  'proke',
  'prone',
  'prove',
  'proze',
  'PS-je',
  'pseje',
  'ptoze',
  'pudre',
  'pufke',
  'puhie',
  'pulke',
  'pulle',
  'pulpe',
  'pulsi',
  'pulti',
  'punge',
  'puple',
  'purre',
  'pushi',
  'pusie',
  'putre',
  'pykle',
  'pylli',
  'qahie',
  'qajke',
  'qarku',
  'qarri',
  'qashe',
  'qatre',
  'qeçke',
  'qefti',
  'qehni',
  'qejfi',
  'qelbi',
  'qelie',
  'qelqi',
  'qemre',
  'QEP-i',
  'qepre',
  'qerbe',
  'qermi',
  'qeske',
  'qethe',
  'qetie',
  'qezri',
  'qibre',
  'qifti',
  'qindi',
  'qiqre',
  'qofke',
  'qokle',
  'qorri',
  'quari',
  'qukle',
  'qukme',
  'qulli',
  'qyeje',
  'qyngu',
  'qyrku',
  'RAB-i',
  'radhe',
  'rafti',
  'rajhu',
  'rakie',
  'rampe',
  'rangu',
  'range',
  'rashe',
  'rasti',
  'razie',
  'reali',
  'reisi',
  'remie',
  'rendi',
  'rënde',
  'rëndi',
  'rënge',
  'rente',
  'rerne',
  'resku',
  'retre',
  'reume',
  'riali',
  'rieli',
  'rigli',
  'ringu',
  'rinie',
  'rioje',
  'risie',
  'risku',
  'ritmi',
  'robie',
  'rojke',
  'rombi',
  'rr-je',
  'rraje',
  'rrage',
  'rrahu',
  'rrapi',
  'rrase',
  'rreje',
  'rrege',
  'rreke',
  'rremi',
  'rreme',
  'rrene',
  'rrepe',
  'rrese',
  'rreve',
  'rrexe',
  'rrëze',
  'rribe',
  'rrice',
  'rrike',
  'rripi',
  'rripe',
  'rrite',
  'rrobe',
  'rroçi',
  'rroge',
  'rroje',
  'rroli',
  'rrote',
  'rrune',
  'rryli',
  'rrymi',
  'rryme',
  'ruble',
  'rubie',
  'rumbe',
  'rundi',
  'runde',
  'ruspe',
  'ruzhi',
  'sabri',
  'saçme',
  'sajne',
  'sajti',
  'salce',
  'salle',
  'sarke',
  'sarsi',
  'sasie',
  'sedre',
  'seksi',
  'sekti',
  'selie',
  'serbi',
  'serie',
  'sermi',
  'serre',
  'seshe',
  'sesie',
  'setre',
  'sfake',
  'sfere',
  'sfide',
  'sfine',
  'sh-je',
  'shahu',
  'shake',
  'shale',
  'Shami',
  'shapi',
  'share',
  'shari',
  'shati',
  'shate',
  'shefi',
  'shege',
  'shehu',
  'sheme',
  'shibe',
  'shili',
  'shine',
  'shire',
  'shite',
  'shogu',
  'shoge',
  'shopi',
  'shori',
  'shoti',
  'shote',
  'shuku',
  'shuli',
  'shume',
  'shuri',
  'shuti',
  'shyte',
  'sidri',
  'sigle',
  'sille',
  'sinie',
  'sirti',
  'sitke',
  'skafi',
  'skaji',
  'skami',
  'skare',
  'skeçi',
  'skede',
  'skele',
  'skeme',
  'skene',
  'skiçi',
  'skice',
  'skiti',
  'skote',
  'skupe',
  'skute',
  'smagu',
  'smati',
  'smire',
  'snobi',
  'sofre',
  'softi',
  'sohie',
  'sonde',
  'sorke',
  'sorre',
  'SOS-i',
  'spati',
  'speci',
  'spice',
  'spiku',
  'spine',
  'spire',
  'spoli',
  'sqapi',
  'sqepi',
  'sqime',
  'sqote',
  'squke',
  'stadi',
  'stafi',
  'stani',
  'stapi',
  'stare',
  'stave',
  'steje',
  'steke',
  'stele',
  'steme',
  'stene',
  'stepe',
  'stike',
  'stili',
  'stine',
  'stive',
  'stofi',
  'stoku',
  'stole',
  'stomi',
  'stufe',
  'suaze',
  'suite',
  'sulmi',
  'surve',
  'suste',
  'syçke',
  'syene',
  'sykau',
  'sythi',
  'syziu',
  'tafti',
  'tagri',
  'tagme',
  'tajge',
  'tajke',
  'tajmi',
  'takre',
  'takse',
  'takti',
  'Talie',
  'talku',
  'talle',
  'tanku',
  'tapie',
  'targe',
  'tasti',
  'TEC-i',
  'teine',
  'tejme',
  'tejze',
  'tempi',
  'tende',
  'tënge',
  'tenje',
  'terce',
  'terie',
  'termi',
  'terri',
  'tersi',
  'teshi',
  'teshe',
  'testi',
  'tezge',
  'th-je',
  'thane',
  'thati',
  'theku',
  'theli',
  'thele',
  'thëne',
  'thepi',
  'thesi',
  'thike',
  'thuku',
  'tiare',
  'tigri',
  'tilde',
  'tilli',
  'tinge',
  'TIR-i',
  'tirku',
  'tizge',
  'tmeze',
  'tokle',
  'torbe',
  'torfe',
  'torku',
  'torke',
  'torte',
  'toske',
  'trage',
  'traku',
  'trapi',
  'trate',
  'traze',
  'tregu',
  'trete',
  'treve',
  'trike',
  'trimi',
  'trine',
  'trive',
  'trize',
  'troku',
  'tropi',
  'trume',
  'trupe',
  'tufle',
  'tufku',
  'tulle',
  'tumbe',
  'turbe',
  'turku',
  'turme',
  'turni',
  'turpi',
  'turri',
  'turre',
  'tushi',
  'tushe',
  'tutle',
  'tutre',
  'tutke',
  'tvidi',
  'UEM-i',
  'ugari',
  'ugiçi',
  'uguje',
  'ujace',
  'ujari',
  'ujdie',
  'ujemi',
  'ujere',
  'ujësi',
  'ujeze',
  'ujthi',
  'ujtie',
  'ulësi',
  'uloku',
  'ulure',
  'unaku',
  'unaze',
  'ungji',
  'UP-je',
  'uprie',
  'urani',
  'urate',
  'ureje',
  'urësi',
  'urëze',
  'ureze',
  'urimi',
  'urine',
  'uriqi',
  'urori',
  'urovi',
  'urthi',
  'urtie',
  'ushti',
  'ushte',
  'ustai',
  'UT-je',
  'uvale',
  'uvule',
  'uzine',
  'uzoje',
  'uzure',
  'vadhe',
  'vaizi',
  'vajze',
  'vakie',
  'vakti',
  'valiu',
  'valsi',
  'vange',
  'varfi',
  'vargu',
  'varke',
  'varri',
  'varre',
  'vashe',
  'vaske',
  'vatre',
  'vathi',
  'vedre',
  'vegle',
  'vegje',
  'vençe',
  'vëngu',
  'vepre',
  'verbi',
  'verge',
  'veriu',
  'versi',
  'vërse',
  'verze',
  'veshe',
  'vesku',
  'vespe',
  'veste',
  'vethi',
  'vëthi',
  'vetie',
  'vicke',
  'vidre',
  'vidhi',
  'vidhe',
  'vigle',
  'vigme',
  'vinçi',
  'viole',
  'viroi',
  'vishe',
  'visku',
  'viske',
  'vizge',
  'vizhe',
  'vjege',
  'vjeli',
  'vlage',
  'vlere',
  'vlese',
  'vlimi',
  'vlugu',
  'vodke',
  'vogli',
  'volie',
  'volti',
  'volte',
  'vorbe',
  'vrage',
  'vrahu',
  'vraje',
  'vrapi',
  'vrari',
  'vrare',
  'vrase',
  'vreri',
  'vreve',
  'vrime',
  'vrugu',
  'vulre',
  'vulve',
  'vurgu',
  'vushe',
  'vuthi',
  'WC-je',
  'xanxe',
  'xh-je',
  'xhaje',
  'xhani',
  'xhazi',
  'xhemi',
  'xhepi',
  'xhike',
  'xhile',
  'xhini',
  'xhipi',
  'xhoke',
  'xholi',
  'xhupi',
  'xingu',
  'yçkle',
  'yllke',
  'zaboi',
  'zagne',
  'zaimi',
  'zalie',
  'zalli',
  'zamke',
  'zapti',
  'zarbe',
  'zarfi',
  'zbimi',
  'zdapi',
  'zebre',
  'zegli',
  'zelli',
  'zemre',
  'zëmre',
  'Zende',
  'zënke',
  'Zeusi',
  'zezke',
  'zgaqi',
  'zgëqi',
  'zgjoi',
  'zgoti',
  'zh-je',
  'zhabe',
  'zhape',
  'zhegu',
  'zhigu',
  'zhive',
  'zholi',
  'zhuge',
  'zhuke',
  'zhuli',
  'zhuri',
  'zhyli',
  'zhymi',
  'zhyme',
  'zhyti',
  'zifti',
  'zigji',
  'zilie',
  'zinku',
  'zogle',
  'Zondi',
  'zonje',
  'zorre',
  'zulme',
  'zushe',
  'zuske',
  'zvoli',
  'kurve',
  'nënte',
  'katre',
  'abasë',
  'abesë',
  'adrës',
  'aedit',
  'afçës',
  'aftës',
  'aftit',
  'agait',
  'ahmës',
  'ahtit',
  'ajrit',
  'ajkës',
  'AKA-s',
  'akçes',
  'aklës',
  'akrës',
  'aknes',
  'aksit',
  'aktit',
  'alesë',
  'alfës',
  'algës',
  'alles',
  'altit',
  'altes',
  'altos',
  'amzës',
  'anies',
  'AP-së',
  'APA-s',
  'ariut',
  'aries',
  'arkës',
  'armës',
  'arnës',
  'artit',
  'ashes',
  'atmes',
  'atusë',
  'aulës',
  'aurës',
  'avrës',
  'avrit',
  'axhës',
  'babës',
  'babit',
  'babos',
  'baçit',
  'bacës',
  'bafës',
  'bagës',
  'bahes',
  'Bakut',
  'bakës',
  'balës',
  'bamit',
  'bamës',
  'banës',
  'basit',
  'batit',
  'bazës',
  'BE-së',
  'bebes',
  'becit',
  'begut',
  'beges',
  'behut',
  'belit',
  'bëmës',
  'benës',
  'berit',
  'bërit',
  'besës',
  'bëzit',
  'bezes',
  'bibës',
  'bicit',
  'bices',
  'bifës',
  'bigës',
  'bijës',
  'bilës',
  'bimës',
  'birit',
  'birës',
  'bitit',
  'bizit',
  'bizës',
  'bleut',
  'blusë',
  'boçit',
  'bocës',
  'boçës',
  'bojës',
  'bokës',
  'bonos',
  'bores',
  'bosit',
  'botit',
  'botes',
  'botës',
  'bovës',
  'bozës',
  'bubës',
  'buçit',
  'bucës',
  'buçes',
  'Budës',
  'bufës',
  'bujës',
  'bulës',
  'bumit',
  'bunit',
  'buqit',
  'buqes',
  'burës',
  'butit',
  'butes',
  'butës',
  'buzës',
  'buzos',
  'bycit',
  'bygës',
  'bykut',
  'bypes',
  'byrit',
  'çaçës',
  'çafit',
  'çajit',
  'cakut',
  'cakës',
  'çakës',
  'çalit',
  'çalës',
  'çamit',
  'capës',
  'çapes',
  'çapës',
  'carit',
  'CD-së',
  'çeçes',
  'çeços',
  'çekut',
  'cekes',
  'cekës',
  'çekes',
  'cemit',
  'çemit',
  'cenit',
  'cenës',
  'cepit',
  'çepit',
  'cëqes',
  'çetës',
  'cezës',
  'çezes',
  'CIA-s',
  'çiçës',
  'cikut',
  'çikut',
  'cikës',
  'çikës',
  'çilit',
  'cilës',
  'çiles',
  'cilit',
  'cimes',
  'cimës',
  'cipit',
  'çipit',
  'cipës',
  'ciqes',
  'cirës',
  'citit',
  'citës',
  'cohës',
  'çojës',
  'çokut',
  'cokës',
  'çokës',
  'çolit',
  'copës',
  'çotës',
  'cubit',
  'cubes',
  'cubës',
  'çubës',
  'çuçit',
  'cucës',
  'çudës',
  'çukut',
  'cukës',
  'çukës',
  'cules',
  'çumës',
  'çunit',
  'cupes',
  'çupës',
  'CV-së',
  'çyçës',
  'cyles',
  'çyrit',
  'dacit',
  'dadës',
  'dados',
  'daiut',
  'dajës',
  'dakut',
  'dakos',
  'dalit',
  'damës',
  'darës',
  'datës',
  'defit',
  'degës',
  'dejës',
  'deles',
  'demit',
  'dëmit',
  'depos',
  'detit',
  'dh-së',
  'dheut',
  'dhisë',
  'digës',
  'dijes',
  'dikës',
  'dinit',
  'dinës',
  'dirës',
  'doçit',
  'doces',
  'doçes',
  'dogës',
  'dokut',
  'domit',
  'dosës',
  'dozës',
  'draut',
  'duasë',
  'dudës',
  'dufit',
  'dukës',
  'dumes',
  'dunës',
  'duqit',
  'ecjes',
  'edhit',
  'edhes',
  'elbit',
  'emrit',
  'emtës',
  'endit',
  'endes',
  'ëndës',
  'engut',
  'enjës',
  'entit',
  'Eosit',
  'epjes',
  'ercit',
  'ergut',
  'erzit',
  'esesë',
  'ethes',
  'etjes',
  'euros',
  'fajit',
  'famës',
  'faqes',
  'farit',
  'fatit',
  'fazës',
  'femes',
  'fesit',
  'fetës',
  'fijes',
  'fikut',
  'filit',
  'finit',
  'firës',
  'firos',
  'fisit',
  'fitit',
  'flisë',
  'fokës',
  'fomit',
  'fotos',
  'ftoit',
  'fugës',
  'futit',
  'fytit',
  'fyzës',
  'gabës',
  'gaçit',
  'gacës',
  'gaçes',
  'gafës',
  'galit',
  'galës',
  'gamës',
  'ganës',
  'garës',
  'gatës',
  'gazit',
  'gazës',
  'geges',
  'gegës',
  'gekos',
  'gelit',
  'gemit',
  'gemës',
  'getos',
  'gicit',
  'gicës',
  'ginës',
  'gizës',
  'gj-së',
  'gjësë',
  'glisë',
  'gnusë',
  'gocës',
  'gofit',
  'gofes',
  'gojës',
  'golit',
  'gomës',
  'gores',
  'gosës',
  'gotës',
  'grosë',
  'gruas',
  'gubes',
  'gubës',
  'gucës',
  'gufes',
  'gufës',
  'gugës',
  'gumës',
  'gunës',
  'gupes',
  'gurit',
  'guvës',
  'gypit',
  'hajes',
  'hakut',
  'hakës',
  'hanit',
  'hapit',
  'hases',
  'hejit',
  'hejës',
  'hekës',
  'hënës',
  'hepit',
  'hesës',
  'hirit',
  'hiçit',
  'hides',
  'hifes',
  'hijes',
  'himes',
  'hipit',
  'hojes',
  'hokës',
  'holes',
  'honit',
  'hopit',
  'horës',
  'hovit',
  'hunit',
  'huqit',
  'hutit',
  'hutës',
  'hyjit',
  'hyrit',
  'idesë',
  'ikrës',
  'ikjes',
  'iksit',
  'ILO-s',
  'ilqes',
  'imtës',
  'inçit',
  'indit',
  'irmës',
  'irnës',
  'ithit',
  'jajës',
  'jakut',
  'jakës',
  'jarit',
  'javës',
  'jazit',
  'jehut',
  'jeles',
  'jenit',
  'jenës',
  'jetës',
  'jodit',
  'jogës',
  'jonit',
  'judit',
  'judës',
  'jugut',
  'jugës',
  'jutës',
  'kaçit',
  'kaçes',
  'kaçës',
  'kades',
  'kafit',
  'kahut',
  'kahes',
  'kakës',
  'kanës',
  'kasës',
  'kecit',
  'keçit',
  'kecës',
  'keçes',
  'kemit',
  'kepit',
  'keqes',
  'kezës',
  'kiçit',
  'kiçes',
  'kikës',
  'kiles',
  'kilës',
  'kimës',
  'kinës',
  'kitit',
  'kizës',
  'kobës',
  'kocit',
  'koçit',
  'koces',
  'kocës',
  'kodit',
  'kodës',
  'kojes',
  'kokut',
  'kokos',
  'komes',
  'komës',
  'konit',
  'kones',
  'koqes',
  'kores',
  'korës',
  'kosës',
  'kotit',
  'kovës',
  'kroit',
  'kreut',
  'KS-së',
  'kubit',
  'kuçit',
  'kujës',
  'kules',
  'kumit',
  'kupës',
  'kuqes',
  'kuqos',
  'kutit',
  'kuzes',
  'kyçit',
  'labit',
  'labes',
  'laçit',
  'lacës',
  'lalës',
  'lamës',
  'lapës',
  'larit',
  'lasës',
  'lasos',
  'latës',
  'lavës',
  'legës',
  'lehes',
  'lejes',
  'lekut',
  'lekës',
  'lelës',
  'lemës',
  'lëmit',
  'leqes',
  'lerës',
  'lesës',
  'levës',
  'lezit',
  'lirit',
  'licës',
  'lidit',
  'ligës',
  'likut',
  'lilës',
  'limës',
  'liqes',
  'lisit',
  'lizës',
  'll-së',
  'lobit',
  'locit',
  'loces',
  'loços',
  'logut',
  'loges',
  'logos',
  'lokes',
  'lolos',
  'lopës',
  'losës',
  'lotit',
  'lozes',
  'lugut',
  'lules',
  'lumes',
  'lumit',
  'lylës',
  'lymit',
  'maçit',
  'maces',
  'majit',
  'majës',
  'makës',
  'malit',
  'mamit',
  'manës',
  'mapos',
  'masës',
  'matit',
  'mazës',
  'meçes',
  'melit',
  'mëmës',
  'merës',
  'metës',
  'mëzit',
  'mëzes',
  'micit',
  'micës',
  'miçës',
  'mijës',
  'mikut',
  'mikes',
  'mikës',
  'mimës',
  'minës',
  'mirës',
  'mirit',
  'misit',
  'mitit',
  'mitës',
  'mizës',
  'modës',
  'mohut',
  'molës',
  'morës',
  'motos',
  'mugut',
  'mugës',
  'murit',
  'mutit',
  'muzës',
  'mycës',
  'myjës',
  'mykut',
  'mykës',
  'mysit',
  'najës',
  'namit',
  'napës',
  'neçes',
  'nefit',
  'nenit',
  'nenës',
  'nënës',
  'nimit',
  'nipit',
  'nj-së',
  'nomit',
  'norit',
  'notit',
  'notës',
  'nulës',
  'numës',
  'nunit',
  'nunës',
  'nurit',
  'nuses',
  'nyjës',
  'oazës',
  'obuas',
  'ogisë',
  'ojmes',
  'ojnës',
  'okrës',
  'okjes',
  'optit',
  'orlit',
  'ortës',
  'oshit',
  'ostes',
  'paçes',
  'pagës',
  'pahut',
  'pajës',
  'pakës',
  'pakos',
  'palës',
  'pamës',
  'panit',
  'papës',
  'Papës',
  'paqes',
  'parës',
  'pares',
  'parit',
  'pasit',
  'patit',
  'patës',
  'PD-së',
  'perit',
  'peces',
  'peçes',
  'pefit',
  'pelës',
  'pemës',
  'penës',
  'picës',
  'pijes',
  'pilës',
  'pinës',
  'pipit',
  'pipës',
  'pisit',
  'pisës',
  'pites',
  'poçit',
  'poçes',
  'polit',
  'ponit',
  'popit',
  'popes',
  'porit',
  'potit',
  'pozës',
  'PR-së',
  'PS-së',
  'psesë',
  'pucit',
  'puçit',
  'pudit',
  'pufes',
  'pulës',
  'pumës',
  'pupës',
  'puros',
  'pusit',
  'putit',
  'pykës',
  'qafës',
  'qagës',
  'qapës',
  'qarit',
  'qases',
  'qasës',
  'qazit',
  'qebes',
  'qelit',
  'qelës',
  'qenit',
  'qenes',
  'qepit',
  'qerit',
  'qeres',
  'qeses',
  'qetes',
  'qezës',
  'qikut',
  'qimes',
  'qokut',
  'qokës',
  'qoles',
  'qores',
  'qoses',
  'qylit',
  'qypit',
  'qypes',
  'qyqit',
  'qyqes',
  'qytës',
  'racës',
  'rajës',
  'ramit',
  'rasës',
  'ravës',
  'rejës',
  'rekut',
  'remit',
  'renit',
  'renës',
  'rënit',
  'rërës',
  'resës',
  'rezes',
  'rezës',
  'rigës',
  'rimit',
  'rimës',
  'risit',
  'ritit',
  'rizës',
  'rodit',
  'rogës',
  'rojit',
  'rojës',
  'rokut',
  'rolit',
  'rolës',
  'rolos',
  'romit',
  'romes',
  'rones',
  'rosës',
  'rozës',
  'rr-së',
  'rubës',
  'rucit',
  'rudës',
  'rulit',
  'rumit',
  'runës',
  'rupit',
  'rusit',
  'rutës',
  'ryzës',
  'saçit',
  'safës',
  'sagës',
  'sajës',
  'sakut',
  'sakës',
  'sanës',
  'sapit',
  'sasës',
  'sazes',
  'seres',
  'serës',
  'sërës',
  'setit',
  'sh-së',
  'sheut',
  'shiut',
  'sidës',
  'sisës',
  'sitës',
  'skisë',
  'sobës',
  'sodës',
  'sojit',
  'sojës',
  'solit',
  'solos',
  'sopit',
  'spisë',
  'sukës',
  'sulit',
  'sules',
  'supit',
  'supës',
  'surit',
  'sures',
  'sutës',
  'syrit',
  'syçit',
  'sykës',
  'syzës',
  'taçit',
  'taçes',
  'tajës',
  'takut',
  'takes',
  'takës',
  'talës',
  'tanit',
  'tapës',
  'tarit',
  'tarës',
  'tasit',
  'tatës',
  'tavës',
  'tehut',
  'tekut',
  'tekes',
  'tekës',
  'telit',
  'temës',
  'tenit',
  'tepit',
  'tepes',
  'tepës',
  'terit',
  'terës',
  'tërës',
  'tetos',
  'tezes',
  'tezës',
  'th-së',
  'thiut',
  'thoit',
  'tifos',
  'tijit',
  'tijes',
  'tijës',
  'tinës',
  'tipit',
  'tires',
  'tirës',
  'tisit',
  'togut',
  'togës',
  'tojës',
  'tonit',
  'Torit',
  'tozit',
  'trios',
  'tubit',
  'tubës',
  'tugës',
  'tujës',
  'tulit',
  'tumës',
  'tunit',
  'tylit',
  'tymit',
  'tytës',
  'ublës',
  'udhës',
  'uebit',
  'ufmës',
  'UFO-s',
  'ugusë',
  'ujkut',
  'uljes',
  'ulzës',
  'umbit',
  'undit',
  'unzës',
  'UP-së',
  'uresë',
  'urisë',
  'urlës',
  'urnës',
  'urrës',
  'urtit',
  'USA-s',
  'usisë',
  'UT-së',
  'uvjes',
  'uxhës',
  'vades',
  'vadës',
  'vajit',
  'valës',
  'vanës',
  'vapës',
  'vasës',
  'vatit',
  'vazit',
  'vazos',
  'Vedes',
  'vegut',
  'vegës',
  'velit',
  'veles',
  'venës',
  'verës',
  'vërës',
  'vesës',
  'vetës',
  'vetos',
  'vezës',
  'vicit',
  'viçit',
  'viçes',
  'vidës',
  'vigut',
  'vigës',
  'vijës',
  'viles',
  'vilës',
  'vinës',
  'virit',
  'visit',
  'vitit',
  'vocit',
  'voces',
  'votës',
  'vovës',
  'vozës',
  'vulës',
  'vutës',
  'vuvit',
  'WC-së',
  'xanës',
  'xëcit',
  'xehes',
  'xh-së',
  'xinës',
  'xipit',
  'xipës',
  'xixës',
  'xuqit',
  'xuqes',
  'yllit',
  'ylles',
  'ymrit',
  'yrtit',
  'ystit',
  'zades',
  'zamit',
  'zamës',
  'zarit',
  'zazës',
  'zërit',
  'zebës',
  'zejes',
  'zekës',
  'zërës',
  'zeros',
  'zëtit',
  'zezës',
  'zh-së',
  'Zilit',
  'ziles',
  'zogut',
  'zogës',
  'zonës',
  'zotit',
  'zuçit',
  'zybës',
  'zyrës',
  'pesës',
  'tetës',
  'abeja',
  'afera',
  'afria',
  'aftra',
  'agava',
  'agmia',
  'ajria',
  'akçja',
  'aknia',
  'akuza',
  'albra',
  'aleja',
  'allja',
  'altja',
  'ameba',
  'amëza',
  'amtia',
  'aneja',
  'anesa',
  'anija',
  'anoda',
  'aorta',
  'AP-ja',
  'arena',
  'arëza',
  'arnia',
  'aroma',
  'ashja',
  'ashra',
  'ashka',
  'ashta',
  'askia',
  'aspra',
  'astma',
  'Atena',
  'athja',
  'Atila',
  'atmja',
  'atuja',
  'azhda',
  'backa',
  'badra',
  'bafra',
  'bahja',
  'bajga',
  'bajza',
  'balta',
  'bamja',
  'banda',
  'banja',
  'banka',
  'barba',
  'barka',
  'barma',
  'barra',
  'basma',
  'batha',
  'baxha',
  'BE-ja',
  'bebja',
  'begia',
  'bejka',
  'belca',
  'bërët',
  'bërja',
  'bezja',
  'bezga',
  'bezha',
  'bibla',
  'bicja',
  'bifka',
  'bigla',
  'bikra',
  'bimla',
  'bimsa',
  'birra',
  'bisha',
  'biska',
  'bixha',
  'bizga',
  'blaca',
  'blana',
  'blata',
  'blega',
  'bleta',
  'blida',
  'bloja',
  'bloza',
  'bluja',
  'bluda',
  'bluza',
  'bobla',
  'boçka',
  'boena',
  'bogla',
  'bokla',
  'boksa',
  'bolba',
  'bolla',
  'bomba',
  'bonja',
  'borja',
  'boria',
  'borma',
  'borsa',
  'boska',
  'botja',
  'bozga',
  'braca',
  'braja',
  'brana',
  'brava',
  'braza',
  'breja',
  'brema',
  'brena',
  'bresa',
  'breva',
  'brica',
  'briga',
  'brima',
  'brisa',
  'briza',
  'broka',
  'bruka',
  'bryma',
  'buaca',
  'bubla',
  'buçja',
  'bufka',
  'buhia',
  'bukla',
  'bukia',
  'bukja',
  'bulga',
  'bulla',
  'bumba',
  'bunda',
  'bunga',
  'buqja',
  'buria',
  'burma',
  'bursa',
  'butja',
  'butët',
  'buzla',
  'bypja',
  'bytha',
  'çaçka',
  'çadra',
  'çafka',
  'çagja',
  'cajka',
  'çajka',
  'çakla',
  'çakra',
  'çakia',
  'çalia',
  'çalla',
  'çamra',
  'canga',
  'çanga',
  'çanta',
  'çapja',
  'çarja',
  'çarta',
  'çatia',
  'CD-ja',
  'çeçja',
  'cefla',
  'çefka',
  'çejna',
  'cekja',
  'çekja',
  'çekia',
  'çelja',
  'cenga',
  'cëqja',
  'cerga',
  'cërka',
  'cerma',
  'çezja',
  'çezma',
  'cibla',
  'cifla',
  'cikla',
  'çikla',
  'çikja',
  'cikma',
  'cikna',
  'çilja',
  'cimja',
  'çimka',
  'cinga',
  'çinia',
  'cinka',
  'ciqja',
  'cirla',
  'citra',
  'çivia',
  'çmesa',
  'cokla',
  'çorba',
  'corka',
  'croga',
  'cubja',
  'çudia',
  'cufla',
  'çufra',
  'cukla',
  'çukla',
  'culja',
  'culla',
  'çulza',
  'cunga',
  'cupja',
  'çurka',
  'curra',
  'çutra',
  'CV-ja',
  'cylja',
  'cytja',
  'dacka',
  'dalët',
  'dalia',
  'dalja',
  'dalta',
  'damka',
  'danga',
  'darka',
  'dasia',
  'dasma',
  'debia',
  'dehja',
  'dejça',
  'dejka',
  'delja',
  'delta',
  'derma',
  'dh-ja',
  'dhëna',
  'dhoga',
  'dhoma',
  'dhuna',
  'dibla',
  'diela',
  'dieta',
  'dinia',
  'dioda',
  'dizga',
  'djega',
  'dobia',
  'docja',
  'doçja',
  'docka',
  'doçka',
  'dogma',
  'domka',
  'dorja',
  'dosja',
  'draga',
  'drama',
  'drega',
  'dreka',
  'drena',
  'drita',
  'driza',
  'droga',
  'droja',
  'druga',
  'duaja',
  'dudia',
  'duhia',
  'duhma',
  'dujka',
  'dukja',
  'dumja',
  'dumka',
  'dunga',
  'dupja',
  'dysia',
  'ecura',
  'edhja',
  'egjra',
  'egoja',
  'elita',
  'ëmbla',
  'endja',
  'ëndja',
  'epika',
  'epoka',
  'eseja',
  'eshka',
  'etapa',
  'ethja',
  'etika',
  'etnia',
  'fajka',
  'falja',
  'falma',
  'faqja',
  'farja',
  'farka',
  'farsa',
  'fasha',
  'fatia',
  'fauna',
  'feçka',
  'fejza',
  'femja',
  'femra',
  'fenda',
  'feria',
  'ferma',
  'ferra',
  'festa',
  'fibra',
  'fikja',
  'finja',
  'firma',
  'flaka',
  'flama',
  'flega',
  'fleta',
  'flija',
  'flora',
  'flota',
  'fluga',
  'folja',
  'folla',
  'forca',
  'forma',
  'fraza',
  'frena',
  'freza',
  'frika',
  'fruta',
  'fryja',
  'fryma',
  'fryra',
  'fryza',
  'ftesa',
  'ftuja',
  'fuçia',
  'fuqia',
  'furça',
  'furia',
  'furka',
  'furra',
  'fusha',
  'fusta',
  'futja',
  'fyrla',
  'gaçja',
  'gaçka',
  'gajba',
  'gajga',
  'ganga',
  'garba',
  'garda',
  'garga',
  'garza',
  'gatra',
  'gavra',
  'gazda',
  'gegia',
  'gërja',
  'germa',
  'gilca',
  'ginga',
  'gj-ja',
  'gjana',
  'gjasa',
  'gjaza',
  'gjëja',
  'gjëma',
  'gjëza',
  'gjiza',
  'gjoba',
  'gjuha',
  'glasa',
  'glepa',
  'gleta',
  'glina',
  'glosa',
  'glota',
  'glyra',
  'gnoma',
  'gnoza',
  'gnuja',
  'godia',
  'gofja',
  'gogla',
  'gojca',
  'gomna',
  'gorja',
  'gorga',
  'gosha',
  'graca',
  'grada',
  'grama',
  'grana',
  'grema',
  'grera',
  'gresa',
  'greva',
  'griba',
  'grifa',
  'griha',
  'grila',
  'grima',
  'grisa',
  'groja',
  'gropa',
  'gruza',
  'gryka',
  'guaca',
  'guaza',
  'gubja',
  'gufja',
  'gufra',
  'gufka',
  'gulfa',
  'gunga',
  'gupja',
  'gurra',
  'gusha',
  'habia',
  'halla',
  'hamja',
  'hapja',
  'harpa',
  'harra',
  'harta',
  'hasja',
  'hasra',
  'havia',
  'hedha',
  'hedia',
  'hejda',
  'hejza',
  'heqla',
  'heqja',
  'hidja',
  'hidra',
  'hiena',
  'hifja',
  'himja',
  'hinka',
  'hipia',
  'hipja',
  'hirra',
  'hitia',
  'holja',
  'honia',
  'hosia',
  'hoxha',
  'huaja',
  'hubia',
  'humba',
  'hunda',
  'hurba',
  'hurma',
  'hyrët',
  'hyria',
  'hyrja',
  'ickla',
  'ideja',
  'idhca',
  'ikona',
  'ilnja',
  'ilqja',
  'imtia',
  'irida',
  'iriza',
  'isoja',
  'jakia',
  'japia',
  'jarga',
  'jelja',
  'jevga',
  'josha',
  'jufka',
  'junta',
  'juria',
  'kaçja',
  'kacia',
  'kaçia',
  'kaçka',
  'kadja',
  'kadha',
  'kafka',
  'kahja',
  'kajla',
  'kakia',
  'kamja',
  'kapia',
  'kapja',
  'kapta',
  'karka',
  'karma',
  'karpa',
  'karta',
  'kaska',
  'kasta',
  'kauza',
  'kazma',
  'keçja',
  'kënda',
  'kënga',
  'kepca',
  'kepja',
  'keqtë',
  'keqja',
  'kërma',
  'kiçja',
  'kikla',
  'kilja',
  'killa',
  'kilza',
  'kimia',
  'kisha',
  'kjoma',
  'klasa',
  'kleka',
  'klika',
  'klima',
  'klita',
  'kmesa',
  'kobra',
  'kocja',
  'koçia',
  'kocka',
  'kodra',
  'kojka',
  'kokla',
  'kolla',
  'komça',
  'komja',
  'konia',
  'konka',
  'kopra',
  'kopja',
  'kopsa',
  'koqja',
  'korba',
  'korda',
  'korja',
  'koria',
  'kreca',
  'kreka',
  'kresa',
  'kreva',
  'krifa',
  'kripa',
  'krisa',
  'kriza',
  'kroca',
  'kroma',
  'krosa',
  'krupa',
  'KS-ja',
  'kuaca',
  'kubla',
  'kucka',
  'kuçka',
  'kudra',
  'kufja',
  'kufka',
  'kulja',
  'kulla',
  'kumia',
  'kunga',
  'kuota',
  'kuqtë',
  'kuqja',
  'kuqla',
  'kurja',
  'kusia',
  'kutia',
  'kuzja',
  'labja',
  'lacra',
  'laçka',
  'lagia',
  'lajka',
  'lakra',
  'lapra',
  'laria',
  'larja',
  'larva',
  'laura',
  'lavra',
  'lebra',
  'ledha',
  'lehja',
  'lemza',
  'lënda',
  'lënga',
  'lenia',
  'lënia',
  'lenta',
  'lepra',
  'leqja',
  'letra',
  'ligtë',
  'limba',
  'limfa',
  'linga',
  'linja',
  'liqja',
  'liria',
  'lisna',
  'lisma',
  'lista',
  'litra',
  'livra',
  'll-ja',
  'llaca',
  'llaja',
  'llaka',
  'llasa',
  'llava',
  'llëra',
  'lloça',
  'lloha',
  'lluca',
  'lluka',
  'lluma',
  'llupa',
  'locja',
  'loçka',
  'lodra',
  'logia',
  'lojba',
  'lokja',
  'lolia',
  'lozja',
  'lozha',
  'lubia',
  'lufta',
  'lukra',
  'lulja',
  'lumja',
  'lunga',
  'lunta',
  'luria',
  'lurka',
  'lusha',
  'luspa',
  'lutja',
  'luzma',
  'lypja',
  'macja',
  'maçka',
  'mafra',
  'mafia',
  'magma',
  'mahia',
  'makra',
  'mamia',
  'manga',
  'mania',
  'manja',
  'marka',
  'marta',
  'masha',
  'maska',
  'matja',
  'matka',
  'mauna',
  'mavia',
  'mazia',
  'mbara',
  'mbesa',
  'meçja',
  'meçka',
  'media',
  'mekja',
  'melca',
  'mella',
  'mënga',
  'menja',
  'mënja',
  'mensa',
  'menta',
  'mëria',
  'mesha',
  'mëzja',
  'miaza',
  'mihja',
  'mijta',
  'mikja',
  'milca',
  'milja',
  'mioza',
  'mirët',
  'miska',
  'mitra',
  'mixha',
  'mjaka',
  'mjeta',
  'mokra',
  'molla',
  'monja',
  'moria',
  'mosha',
  'motra',
  'mreça',
  'mufla',
  'mufka',
  'mujza',
  'mumia',
  'munxa',
  'murga',
  'murra',
  'muzga',
  'mykja',
  'nafta',
  'nalla',
  'ndala',
  'ndara',
  'ndeja',
  'ndeza',
  'ndija',
  'nduka',
  'neçja',
  'nejça',
  'netra',
  'ngaja',
  'ngeja',
  'ngeca',
  'nimfa',
  'nisia',
  'nisja',
  'nisma',
  'nj-ja',
  'njaça',
  'njeka',
  'njila',
  'noçka',
  'nofka',
  'nojma',
  'norma',
  'notia',
  'nulla',
  'nunla',
  'nunra',
  'nusja',
  'nusla',
  'nuska',
  'ojara',
  'ojmja',
  'oliva',
  'opera',
  'ormia',
  'osaka',
  'osela',
  'oshta',
  'ostja',
  'ozena',
  'paçja',
  'padia',
  'pafka',
  'pafta',
  'pahia',
  'palca',
  'palla',
  'palma',
  'pamja',
  'panda',
  'panja',
  'paqja',
  'parja',
  'parët',
  'paria',
  'parma',
  'pasia',
  'pasja',
  'pasta',
  'pauza',
  'pazia',
  'PD-ja',
  'pecja',
  'peçja',
  'pecka',
  'pejza',
  'pella',
  'pemla',
  'pemza',
  'penda',
  'penga',
  'perla',
  'pesha',
  'pesta',
  'petla',
  'pikla',
  'pikja',
  'pilca',
  'pilla',
  'pinca',
  'pipra',
  'piria',
  'pirja',
  'pirra',
  'pisha',
  'piska',
  'pista',
  'pitja',
  'pitra',
  'pizla',
  'pizga',
  'pjaca',
  'pjata',
  'pjeka',
  'pjesa',
  'plaga',
  'plaka',
  'plana',
  'plasa',
  'ploja',
  'poçja',
  'poema',
  'pofka',
  'polka',
  'pompa',
  'popja',
  'popla',
  'porta',
  'posta',
  'PR-ja',
  'praja',
  'preja',
  'preka',
  'prera',
  'presa',
  'preva',
  'prija',
  'prika',
  'prira',
  'prita',
  'priza',
  'proka',
  'prona',
  'prova',
  'proza',
  'PS-ja',
  'pseja',
  'ptoza',
  'pudra',
  'pufja',
  'puhia',
  'pulka',
  'pulpa',
  'punga',
  'pupla',
  'puqja',
  'purra',
  'pusia',
  'putra',
  'pykla',
  'qahia',
  'qajka',
  'qarët',
  'qarja',
  'qasja',
  'qasha',
  'qatra',
  'qebja',
  'qeçka',
  'qelia',
  'qemra',
  'qenia',
  'qenët',
  'qepra',
  'qepja',
  'qerba',
  'qerja',
  'qesja',
  'qeska',
  'qetja',
  'qetha',
  'qetia',
  'qibra',
  'qimja',
  'qiqra',
  'qitja',
  'qofka',
  'qokla',
  'qolja',
  'qorja',
  'qosja',
  'qukla',
  'qukma',
  'qyeja',
  'qypja',
  'qyqja',
  'radha',
  'rakia',
  'rampa',
  'ranga',
  'rasha',
  'razia',
  'remia',
  'rënda',
  'rënga',
  'rënia',
  'renta',
  'rerna',
  'retra',
  'reuma',
  'rezja',
  'rinia',
  'rioja',
  'risia',
  'robia',
  'rojka',
  'romja',
  'ronia',
  'rr-ja',
  'rraja',
  'rraga',
  'rrasa',
  'rreja',
  'rrega',
  'rreka',
  'rrema',
  'rrena',
  'rrepa',
  'rreva',
  'rrexa',
  'rrëza',
  'rriba',
  'rrika',
  'rrita',
  'rroba',
  'rroga',
  'rroja',
  'rroka',
  'rrota',
  'rrufa',
  'rruga',
  'rruna',
  'rryma',
  'rubla',
  'rubia',
  'rumba',
  'runda',
  'ruspa',
  'saçma',
  'sajna',
  'salca',
  'salla',
  'sarka',
  'sasia',
  'sazja',
  'sedra',
  'selia',
  'sepja',
  'serja',
  'seria',
  'serra',
  'sesha',
  'sesia',
  'setra',
  'sfaka',
  'sfera',
  'sfida',
  'sfina',
  'sh-ja',
  'shala',
  'shara',
  'shata',
  'shega',
  'shema',
  'shiba',
  'shija',
  'shina',
  'shita',
  'shoga',
  'shoka',
  'shota',
  'sigla',
  'silla',
  'sinia',
  'sitja',
  'skara',
  'skeda',
  'skela',
  'skema',
  'skena',
  'skica',
  'skota',
  'skupa',
  'skuta',
  'smira',
  'sofra',
  'sohia',
  'sonda',
  'sorka',
  'sorra',
  'sosja',
  'spica',
  'spina',
  'spira',
  'sqima',
  'sqota',
  'squka',
  'stara',
  'stava',
  'steja',
  'steka',
  'stela',
  'stema',
  'stena',
  'stepa',
  'stika',
  'stina',
  'stiva',
  'stola',
  'stufa',
  'suaza',
  'suita',
  'sulja',
  'surja',
  'surva',
  'susta',
  'syçka',
  'syena',
  'taçja',
  'tagma',
  'tajga',
  'tajka',
  'takja',
  'takra',
  'taksa',
  'Talia',
  'talla',
  'tapia',
  'targa',
  'teina',
  'tejma',
  'tejza',
  'tekja',
  'tenda',
  'tënga',
  'tenja',
  'tepja',
  'terca',
  'teria',
  'terja',
  'tesha',
  'tezja',
  'tezga',
  'th-ja',
  'thana',
  'theka',
  'thëna',
  'thika',
  'tiara',
  'tilda',
  'tinga',
  'tirja',
  'tizga',
  'tmeza',
  'tokla',
  'torba',
  'torfa',
  'torka',
  'torta',
  'toska',
  'traga',
  'trata',
  'traza',
  'trema',
  'treta',
  'treva',
  'trika',
  'trina',
  'triva',
  'triza',
  'troka',
  'truma',
  'trupa',
  'tufla',
  'tulla',
  'tumba',
  'turba',
  'turma',
  'tusha',
  'tutla',
  'tutra',
  'tutka',
  'uguja',
  'ujaca',
  'ujdia',
  'ujera',
  'ujeza',
  'ujtia',
  'ulura',
  'UP-ja',
  'upria',
  'urata',
  'ureja',
  'urëza',
  'ureza',
  'urina',
  'urtia',
  'ushta',
  'UT-ja',
  'uvala',
  'uvula',
  'uzina',
  'uzoja',
  'uzura',
  'vadja',
  'vadha',
  'vajza',
  'vakia',
  'vakja',
  'vanga',
  'vapja',
  'varja',
  'varka',
  'varra',
  'vasha',
  'vaska',
  'vatra',
  'vatha',
  'Vedja',
  'vedra',
  'vegla',
  'vegja',
  'velja',
  'vemja',
  'vença',
  'vënia',
  'vepra',
  'verga',
  'vërsa',
  'verza',
  'vesha',
  'vespa',
  'vesta',
  'vetia',
  'viçja',
  'vicka',
  'vidha',
  'vigla',
  'vigma',
  'vilja',
  'viola',
  'visha',
  'viska',
  'vizga',
  'vizha',
  'vjega',
  'vlaga',
  'vlera',
  'vlesa',
  'vocja',
  'vodka',
  'volia',
  'volta',
  'vorba',
  'vraga',
  'vraja',
  'vrara',
  'vrasa',
  'vreva',
  'vulra',
  'vulva',
  'vusha',
  'WC-ja',
  'xanxa',
  'xehja',
  'xh-ja',
  'xhaja',
  'xhika',
  'xhila',
  'xhoka',
  'xuqja',
  'yçkla',
  'yllja',
  'yllka',
  'zadja',
  'zagna',
  'zalia',
  'zamka',
  'zarba',
  'zebra',
  'zemra',
  'zëmra',
  'Zenda',
  'zënia',
  'zënka',
  'zezka',
  'zh-ja',
  'zhaba',
  'zhaka',
  'zhapa',
  'zhiva',
  'zhuga',
  'zhuka',
  'zhyma',
  'zilja',
  'zilia',
  'zogla',
  'zorra',
  'zulma',
  'zusha',
  'zuska',
  'kurva',
  'kyçja',
  'nënta',
  'katra',
  'abanë',
  'abenë',
  'adrën',
  'aedin',
  'afçën',
  'aftën',
  'aftin',
  'again',
  'ahmën',
  'ahtin',
  'ajrin',
  'ajkën',
  'AKA-n',
  'akçen',
  'aklën',
  'akrën',
  'aknen',
  'aksin',
  'alenë',
  'alfën',
  'algën',
  'allen',
  'altin',
  'alten',
  'alton',
  'amzën',
  'anenë',
  'anien',
  'AP-në',
  'APA-n',
  'ariun',
  'arien',
  'arkën',
  'armën',
  'arnën',
  'arrën',
  'artin',
  'ashen',
  'atmen',
  'atunë',
  'aulën',
  'aurën',
  'avrën',
  'avrin',
  'axhën',
  'babën',
  'babin',
  'babon',
  'baçin',
  'bacën',
  'bafën',
  'bagën',
  'bahen',
  'Bakun',
  'bakën',
  'balën',
  'balon',
  'bamin',
  'bamën',
  'banën',
  'barin',
  'basin',
  'batin',
  'bazën',
  'BE-në',
  'beben',
  'becin',
  'begen',
  'behun',
  'belin',
  'bëmën',
  'benën',
  'berin',
  'bërën',
  'besën',
  'bëzin',
  'bezen',
  'bibën',
  'bicin',
  'bicen',
  'bifën',
  'bigën',
  'bijën',
  'bilën',
  'bimën',
  'birin',
  'birën',
  'bitin',
  'bizin',
  'bizën',
  'bleun',
  'blunë',
  'boçin',
  'bocën',
  'boçën',
  'bojën',
  'bokën',
  'bonon',
  'borin',
  'boren',
  'borën',
  'bosin',
  'botin',
  'boten',
  'botën',
  'bovën',
  'bozën',
  'bubën',
  'buçin',
  'bucën',
  'buçen',
  'Budën',
  'bufin',
  'bufën',
  'bujën',
  'bukën',
  'bulën',
  'bumin',
  'bunin',
  'buqin',
  'buqen',
  'burën',
  'butin',
  'buten',
  'butën',
  'buzën',
  'buzon',
  'bycin',
  'bygën',
  'bykun',
  'bypen',
  'byrin',
  'çaçën',
  'çafin',
  'çajin',
  'cakun',
  'cakën',
  'çakën',
  'çalin',
  'çalën',
  'çamin',
  'çapin',
  'capën',
  'çapen',
  'çapën',
  'carin',
  'çarën',
  'CD-në',
  'çeçen',
  'çeçon',
  'çekun',
  'ceken',
  'cekën',
  'çeken',
  'çelën',
  'cemin',
  'çemin',
  'cenin',
  'cenën',
  'cepin',
  'çepin',
  'cëqen',
  'cërin',
  'çetën',
  'cezën',
  'çezen',
  'CIA-n',
  'çiçën',
  'cikun',
  'çikun',
  'cikën',
  'çikën',
  'çilin',
  'cilën',
  'çilen',
  'cilin',
  'cimen',
  'cimën',
  'cipin',
  'çipin',
  'cipën',
  'ciqen',
  'cirën',
  'citin',
  'citën',
  'cohën',
  'çojën',
  'çokun',
  'cokën',
  'çokën',
  'çolin',
  'copën',
  'çotën',
  'cubin',
  'cuben',
  'cubën',
  'çubën',
  'çuçin',
  'cucën',
  'çudën',
  'çukun',
  'cukën',
  'çukën',
  'culen',
  'çumën',
  'çunin',
  'cupen',
  'çupën',
  'CV-në',
  'çyçën',
  'cylen',
  'çyrin',
  'cytën',
  'dacin',
  'dadën',
  'dadon',
  'daiun',
  'dajën',
  'dakun',
  'dakon',
  'dalën',
  'damën',
  'darën',
  'datën',
  'defin',
  'degën',
  'dejën',
  'delen',
  'demin',
  'dëmin',
  'depon',
  'derën',
  'detin',
  'dh-në',
  'dheun',
  'dhinë',
  'digën',
  'dijen',
  'dikën',
  'dinin',
  'dinën',
  'dirën',
  'divin',
  'doçin',
  'docen',
  'doçen',
  'dogën',
  'dokun',
  'domin',
  'dorën',
  'dosën',
  'dozën',
  'draun',
  'duanë',
  'dudën',
  'dufin',
  'dukën',
  'dunën',
  'duqin',
  'ecjen',
  'edhin',
  'edhen',
  'elbin',
  'emrin',
  'emtën',
  'endin',
  'enden',
  'endën',
  'ëndën',
  'engun',
  'enjën',
  'entin',
  'Eosin',
  'epjen',
  'ercin',
  'ergun',
  'erzin',
  'esenë',
  'eshin',
  'ethen',
  'etjen',
  'euron',
  'fajin',
  'falën',
  'famën',
  'faqen',
  'farin',
  'farën',
  'fatin',
  'fazën',
  'femen',
  'fesin',
  'fetën',
  'fijen',
  'fikun',
  'fikën',
  'filin',
  'finin',
  'firën',
  'firon',
  'fisin',
  'fitin',
  'flinë',
  'fokën',
  'fomin',
  'fugën',
  'futin',
  'futën',
  'fytin',
  'fyzën',
  'gabën',
  'gaçin',
  'gacën',
  'gaçen',
  'gafën',
  'galin',
  'galën',
  'gamën',
  'ganën',
  'garën',
  'gatën',
  'gazin',
  'gazën',
  'gegen',
  'gegën',
  'gekon',
  'gelin',
  'gemin',
  'gemën',
  'geton',
  'gicin',
  'gicën',
  'ginën',
  'gizën',
  'gj-në',
  'gjënë',
  'gnunë',
  'gocën',
  'gofin',
  'gofen',
  'gojën',
  'golin',
  'gosën',
  'gotën',
  'gronë',
  'gruan',
  'guben',
  'gubën',
  'gucën',
  'gufen',
  'gufën',
  'gugën',
  'gumën',
  'gunën',
  'gupen',
  'gurin',
  'guvën',
  'gypin',
  'hajen',
  'hakun',
  'hakën',
  'halën',
  'hanin',
  'hapin',
  'hasen',
  'hejin',
  'hejën',
  'hekën',
  'hënën',
  'hepin',
  'herën',
  'hesën',
  'hirin',
  'hiçin',
  'hiden',
  'hifen',
  'hijen',
  'hipin',
  'hojen',
  'hokën',
  'holen',
  'honin',
  'hopin',
  'horën',
  'hovin',
  'hunin',
  'huqin',
  'hutën',
  'hyjin',
  'hyrën',
  'idenë',
  'ikrën',
  'ikjen',
  'iksin',
  'ILO-n',
  'ilqen',
  'imtën',
  'inçin',
  'indin',
  'irmën',
  'irnën',
  'ithin',
  'jajën',
  'jakun',
  'jakën',
  'jarin',
  'javën',
  'jazin',
  'jehun',
  'jelen',
  'jenin',
  'jenën',
  'jetën',
  'jodin',
  'jogën',
  'jonin',
  'judin',
  'judën',
  'jugun',
  'jugën',
  'jutën',
  'kaçin',
  'kaçen',
  'kaçën',
  'kaden',
  'kafin',
  'kahun',
  'kahen',
  'kakën',
  'kalin',
  'kamën',
  'kanën',
  'kapën',
  'kasën',
  'katin',
  'kecin',
  'keçin',
  'kecën',
  'keçen',
  'kemin',
  'kepin',
  'keqen',
  'kezën',
  'kiçin',
  'kiçen',
  'kikën',
  'kilen',
  'kilën',
  'kimën',
  'kinën',
  'kitin',
  'kitën',
  'kizën',
  'kobin',
  'kobën',
  'kocin',
  'koçin',
  'kocen',
  'kocën',
  'kodin',
  'kodën',
  'kohën',
  'kojen',
  'kokun',
  'kokën',
  'kokon',
  'komin',
  'komen',
  'komën',
  'konin',
  'konen',
  'koqen',
  'korin',
  'koren',
  'korën',
  'kosin',
  'kosën',
  'kotin',
  'kovën',
  'krinë',
  'kreun',
  'KS-në',
  'kubin',
  'kuçin',
  'kujën',
  'kulen',
  'kumin',
  'kupën',
  'kuqen',
  'kurën',
  'kutin',
  'kuzen',
  'kyçin',
  'labin',
  'laben',
  'laçin',
  'lacën',
  'lagën',
  'lakun',
  'lalën',
  'lamën',
  'lapën',
  'larin',
  'larën',
  'laron',
  'lasën',
  'lason',
  'latën',
  'lavën',
  'legën',
  'lehen',
  'lejen',
  'lekun',
  'lelën',
  'lemën',
  'leqen',
  'lerën',
  'lesën',
  'levën',
  'lezin',
  'lirin',
  'licën',
  'lidin',
  'ligën',
  'likun',
  'lilën',
  'limën',
  'lirën',
  'lisin',
  'lizën',
  'll-në',
  'lobin',
  'locin',
  'locen',
  'loçon',
  'logun',
  'logen',
  'logon',
  'lojën',
  'loken',
  'lolon',
  'lopën',
  'losën',
  'lotin',
  'lozen',
  'lugun',
  'lugën',
  'lulen',
  'lumen',
  'lumin',
  'lutën',
  'lylën',
  'lymin',
  'lypën',
  'lyrën',
  'maçin',
  'macen',
  'majin',
  'majën',
  'makën',
  'malin',
  'mamin',
  'manin',
  'manën',
  'mapon',
  'masën',
  'matin',
  'mazën',
  'meçen',
  'melin',
  'mëmën',
  'merën',
  'mesin',
  'mesën',
  'metën',
  'mëzin',
  'mëzen',
  'micin',
  'micën',
  'miçën',
  'mijën',
  'mikun',
  'miken',
  'mikën',
  'mimën',
  'minën',
  'mirën',
  'miron',
  'misin',
  'mitin',
  'mitën',
  'mizën',
  'modën',
  'mohun',
  'molin',
  'molën',
  'morën',
  'motin',
  'moton',
  'mugun',
  'mugën',
  'murin',
  'mutin',
  'muzën',
  'mycën',
  'myjën',
  'mykun',
  'mykën',
  'mysin',
  'najën',
  'namin',
  'napën',
  'neçen',
  'nefin',
  'nëmën',
  'nenin',
  'nenën',
  'nënën',
  'ngenë',
  'nimin',
  'nipin',
  'nj-në',
  'nomin',
  'norin',
  'notin',
  'notën',
  'nulën',
  'numën',
  'nunin',
  'nunën',
  'nurin',
  'nusen',
  'nyjën',
  'oazën',
  'obuan',
  'oginë',
  'ojmen',
  'ojnën',
  'okrën',
  'okjen',
  'optin',
  'orlin',
  'ortën',
  'oshin',
  'ostin',
  'osten',
  'paçen',
  'pagën',
  'pahun',
  'pajën',
  'pakën',
  'pakon',
  'palën',
  'pamën',
  'panin',
  'papën',
  'Papën',
  'paqen',
  'parën',
  'paren',
  'parin',
  'pasin',
  'patin',
  'patën',
  'PD-në',
  'perin',
  'pecen',
  'peçen',
  'pefin',
  'pelën',
  'pemën',
  'penën',
  'petin',
  'petën',
  'picën',
  'pijen',
  'pikën',
  'pilën',
  'pinën',
  'pipin',
  'pipën',
  'pirin',
  'pisin',
  'pisën',
  'piten',
  'poçin',
  'poçen',
  'polin',
  'ponin',
  'popin',
  'popen',
  'porin',
  'potin',
  'pozën',
  'PR-në',
  'prenë',
  'PS-në',
  'psenë',
  'pucin',
  'puçin',
  'pudin',
  'pufen',
  'pulën',
  'pumën',
  'punën',
  'pupën',
  'puron',
  'pusin',
  'putin',
  'putën',
  'pykën',
  'qafën',
  'qagën',
  'qapën',
  'qarin',
  'qarën',
  'qasen',
  'qasën',
  'qazin',
  'qeben',
  'qelin',
  'qelën',
  'qenin',
  'qenen',
  'qepin',
  'qepën',
  'qerin',
  'qeren',
  'qesen',
  'qeten',
  'qetën',
  'qezën',
  'qikun',
  'qimen',
  'qokun',
  'qokën',
  'qolen',
  'qoren',
  'qosen',
  'qukën',
  'qylin',
  'qypin',
  'qypen',
  'qyqin',
  'qyqen',
  'qyrën',
  'qytën',
  'RAB-n',
  'racën',
  'rajën',
  'ramin',
  'rasën',
  'ratën',
  'ravën',
  'rejën',
  'rekun',
  'remin',
  'renin',
  'renën',
  'rënën',
  'rënin',
  'rërën',
  'resën',
  'rezen',
  'rezën',
  'rigën',
  'rikën',
  'rimin',
  'rimën',
  'risin',
  'ritin',
  'rizën',
  'robin',
  'rodin',
  'rogën',
  'rojin',
  'rojën',
  'rokun',
  'rolin',
  'rolën',
  'rolon',
  'romin',
  'romen',
  'ronen',
  'rosën',
  'rozën',
  'rr-në',
  'rranë',
  'rubën',
  'rucin',
  'rudën',
  'rulin',
  'rumin',
  'runën',
  'rupin',
  'rusin',
  'rutën',
  'ryzën',
  'saçin',
  'safën',
  'sagën',
  'sajën',
  'sakun',
  'sakën',
  'sanën',
  'sapin',
  'sasën',
  'sazen',
  'seren',
  'serën',
  'sërën',
  'setin',
  'sh-në',
  'sheun',
  'shiun',
  'sidën',
  'sisën',
  'sitën',
  'skinë',
  'sobën',
  'sodën',
  'sojin',
  'sojën',
  'solin',
  'solon',
  'sopin',
  'sukën',
  'sulin',
  'sulen',
  'supën',
  'surin',
  'suren',
  'sutën',
  'syrin',
  'syçin',
  'sykën',
  'syzën',
  'taçin',
  'taçen',
  'tajën',
  'takun',
  'taken',
  'takën',
  'talën',
  'tapën',
  'tarin',
  'tarën',
  'tasin',
  'tatën',
  'tavën',
  'tehun',
  'tekun',
  'teken',
  'tekën',
  'telin',
  'temën',
  'tenin',
  'tepin',
  'tepen',
  'tepën',
  'terin',
  'terën',
  'tërën',
  'teton',
  'tezen',
  'tezën',
  'th-në',
  'thiun',
  'tifon',
  'tijin',
  'tijen',
  'tijën',
  'tinën',
  'tipin',
  'tiren',
  'tirën',
  'tisin',
  'toçin',
  'togun',
  'togën',
  'tojën',
  'tokën',
  'tonin',
  'topin',
  'Torin',
  'tozin',
  'trion',
  'tubin',
  'tubën',
  'tufën',
  'tugën',
  'tujën',
  'tulin',
  'tumën',
  'tunin',
  'tutën',
  'tylin',
  'tymin',
  'tytën',
  'ublën',
  'udhën',
  'uebin',
  'ufmën',
  'UFO-n',
  'ugunë',
  'ujkun',
  'uljen',
  'ulzën',
  'umbin',
  'undin',
  'unzën',
  'UP-në',
  'urenë',
  'urlën',
  'urnën',
  'urrën',
  'urtin',
  'USA-n',
  'usinë',
  'UT-në',
  'uvjen',
  'uxhën',
  'vaden',
  'vadën',
  'vajin',
  'vajën',
  'valën',
  'vanën',
  'vapën',
  'vasën',
  'vatin',
  'vazin',
  'vazon',
  'Veden',
  'vegun',
  'vegën',
  'velin',
  'velen',
  'venën',
  'verën',
  'vërën',
  'vesin',
  'vesën',
  'vetën',
  'vezën',
  'vicin',
  'viçin',
  'viçen',
  'vidën',
  'vigun',
  'vigën',
  'vijën',
  'vilen',
  'vilën',
  'vinën',
  'virin',
  'visin',
  'vitin',
  'vizën',
  'vocin',
  'vocen',
  'volin',
  'votën',
  'vovën',
  'vozin',
  'vozën',
  'vulën',
  'vutën',
  'vuvin',
  'WC-në',
  'xanën',
  'xëcin',
  'xehen',
  'xh-në',
  'xinën',
  'xipin',
  'xipën',
  'xixën',
  'xuqin',
  'xuqen',
  'yllin',
  'yllen',
  'ymrin',
  'yrtin',
  'ystin',
  'zaden',
  'zamin',
  'zamën',
  'zanën',
  'zarin',
  'zazën',
  'zërin',
  'zebën',
  'zejen',
  'zekën',
  'zërën',
  'zeron',
  'zëtin',
  'zezën',
  'zh-në',
  'Zilin',
  'zilen',
  'zogun',
  'zogën',
  'zonën',
  'zorin',
  'zotin',
  'zuçin',
  'zybën',
  'zyrën',
  'pesën',
  'tetën',
  'abeve',
  'afeve',
  'aheve',
  'ajeve',
  'aleve',
  'ameve',
  'amave',
  'aneve',
  'anëve',
  'AP-ve',
  'apëve',
  'arëve',
  'arave',
  'aseve',
  'atuve',
  'BE-ve',
  'bijve',
  'CD-ve',
  'CV-ve',
  'dejve',
  'dh-ve',
  'dhive',
  'duave',
  'egove',
  'ëmave',
  'enëve',
  'epëve',
  'eseve',
  'fiqve',
  'flive',
  'gj-ve',
  'glive',
  'gnuve',
  'grove',
  'grave',
  'huave',
  'hyjve',
  'içave',
  'ideve',
  'ijëve',
  'ivave',
  'krive',
  'KS-ve',
  'leive',
  'liqve',
  'll-ve',
  'miqve',
  'nj-ve',
  'odeve',
  'odave',
  'ojave',
  'okëve',
  'omëve',
  'orëve',
  'PD-ve',
  'PR-ve',
  'PS-ve',
  'pseve',
  'qenve',
  'qyeve',
  'riove',
  'rr-ve',
  'rrave',
  'sh-ve',
  'skive',
  'spive',
  'th-ve',
  'thive',
  'tijve',
  'truve',
  'unëve',
  'UP-ve',
  'uteve',
  'UT-ve',
  'WC-ve',
  'xh-ve',
  'zh-ve',
  'boave',
  'abakë',
  'abatë',
  'abejë',
  'abise',
  'abusë',
  'abuze',
  'acide',
  'adete',
  'adutë',
  'afate',
  'afelë',
  'afshe',
  'agatë',
  'agime',
  'ahure',
  'ajarë',
  'akshe',
  'akthe',
  'alete',
  'allce',
  'allçë',
  'amide',
  'amile',
  'anakë',
  'anime',
  'anuse',
  'apele',
  'arabë',
  'arapë',
  'ararë',
  'arguj',
  'arinj',
  'armiq',
  'arrça',
  'atakë',
  'atole',
  'atome',
  'avalë',
  'avarë',
  'avaze',
  'avisë',
  'azile',
  'bahte',
  'balça',
  'barna',
  'bardë',
  'barqe',
  'bashe',
  'baskë',
  'baste',
  'belgë',
  'bende',
  'berqe',
  'berra',
  'bisqe',
  'blice',
  'blofe',
  'boerë',
  'bokaj',
  'bonzë',
  'braçë',
  'brakë',
  'bretq',
  'breza',
  'brirë',
  'bruça',
  'burra',
  'buste',
  'buzme',
  'bykra',
  'cacra',
  'çafra',
  'çaire',
  'çarqe',
  'çaste',
  'çataj',
  'cecuj',
  'cedra',
  'cegma',
  'çejza',
  'cense',
  'centë',
  'çerra',
  'cianë',
  'cimba',
  'çipsa',
  'cipuj',
  'cirqe',
  'ciste',
  'çmime',
  'çulle',
  'cumra',
  'dainj',
  'derra',
  'derte',
  'dhera',
  'diezë',
  'dimra',
  'disqe',
  'domër',
  'drape',
  'drerë',
  'dresë',
  'dromë',
  'drume',
  'dryna',
  'duele',
  'duete',
  'dumba',
  'dushe',
  'dysha',
  'edeme',
  'ekipe',
  'elanë',
  'elatë',
  'emirë',
  'epode',
  'epose',
  'epshe',
  'erakë',
  'erarë',
  'erëra',
  'esivë',
  'etane',
  'etere',
  'etile',
  'etyde',
  'ezanë',
  'faize',
  'fakse',
  'fakte',
  'falle',
  'famuj',
  'fansa',
  'fante',
  'feude',
  'filma',
  'firnë',
  'fliqe',
  'flokë',
  'fokse',
  'fonde',
  'fonte',
  'frakë',
  'frerë',
  'frone',
  'fryte',
  'ftonj',
  'funte',
  'galca',
  'gamuj',
  'gande',
  'gathë',
  'gauçë',
  'gaurë',
  'gausë',
  'gërbe',
  'gipse',
  'gjame',
  'gjëra',
  'gjela',
  'gjene',
  'gjepa',
  'gjera',
  'gjire',
  'gjoca',
  'gjokë',
  'gjole',
  'gjyma',
  'gjyqe',
  'glifë',
  'globe',
  'gonge',
  'gopça',
  'grecë',
  'grekë',
  'grenë',
  'grepa',
  'gripa',
  'grizë',
  'grofë',
  'grogë',
  'gruça',
  'grupe',
  'gulçe',
  'gulme',
  'gurdë',
  'hajna',
  'harca',
  'harça',
  'harqe',
  'hasmë',
  'haure',
  'hauze',
  'hercë',
  'herra',
  'hiate',
  'himne',
  'horra',
  'idhuj',
  'idile',
  'ilaçe',
  'iliqe',
  'ilirë',
  'imakë',
  'imamë',
  'inate',
  'IOM-ë',
  'irate',
  'iriqë',
  'ishuj',
  'istme',
  'jahte',
  'jambe',
  'jardë',
  'jevgj',
  'juanë',
  'kalke',
  'kampe',
  'katha',
  'kauçë',
  'kaurë',
  'kedhë',
  'KEK-ë',
  'keksë',
  'kerre',
  'kërre',
  'këste',
  'ketra',
  'kinda',
  'kipca',
  'klane',
  'klone',
  'klube',
  'kmerë',
  'KON-ë',
  'kontë',
  'kosha',
  'krahë',
  'krane',
  'krasë',
  'krepa',
  'krikë',
  'krime',
  'kroje',
  'krerë',
  'kudha',
  'kufij',
  'kuleç',
  'kulme',
  'kulte',
  'kumbe',
  'kunja',
  'kurdë',
  'kurme',
  'laikë',
  'lajme',
  'lapsa',
  'lavde',
  'lekna',
  'lepuj',
  'libra',
  'lifte',
  'linçe',
  'linda',
  'llafe',
  'llobë',
  'lloje',
  'lloze',
  'logje',
  'lordë',
  'luanë',
  'lugje',
  'lukse',
  'lytha',
  'mëdha',
  'malmë',
  'marse',
  'maurë',
  'meitë',
  'mërçe',
  'metra',
  'mëtij',
  'mille',
  'miomë',
  'miopë',
  'misra',
  'mjekë',
  'morfe',
  'morra',
  'mrize',
  'mugje',
  'muguj',
  'murgj',
  'mushq',
  'mutra',
  'mylqe',
  'myrgë',
  'naivë',
  'ndalq',
  'ndere',
  'neone',
  'nepse',
  'nerva',
  'netha',
  'nguca',
  'ngule',
  'niobë',
  'numra',
  'obatë',
  'obolë',
  'obusë',
  'ofiqe',
  'ofise',
  'ofshe',
  'ogiçë',
  'ogure',
  'okapë',
  'okarë',
  'omanë',
  'opalë',
  'opatë',
  'opuse',
  'orare',
  'orokë',
  'orumë',
  'otite',
  'ovalë',
  'ovulë',
  'paorë',
  'PAP-ë',
  'parqe',
  'pazhë',
  'pëlla',
  'pense',
  'peone',
  'përça',
  'persë',
  'peshq',
  'petha',
  'petka',
  'pionë',
  'plafe',
  'plepa',
  'pliqe',
  'plisa',
  'plore',
  'pluse',
  'poenë',
  'poetë',
  'pontë',
  'popuj',
  'prasë',
  'pulse',
  'pulte',
  'qarqe',
  'qarre',
  'qefte',
  'qefuj',
  'qehna',
  'qejfe',
  'QEP-ë',
  'qerme',
  'qezra',
  'qifte',
  'qipuj',
  'qorra',
  'quarë',
  'qyqër',
  'qyrqe',
  'RAB-ë',
  'rafte',
  'rajhë',
  'raste',
  'realë',
  'regjë',
  'reisë',
  'rialë',
  'rielë',
  'ritme',
  'robër',
  'rombe',
  'rrahe',
  'rrape',
  'rroça',
  'rrola',
  'rryla',
  'ruzhë',
  'sajte',
  'sarse',
  'sekse',
  'sekte',
  'sekuj',
  'sende',
  'serbë',
  'shahë',
  'shera',
  'shefa',
  'shopa',
  'shorë',
  'shuka',
  'shulq',
  'shute',
  'sidra',
  'sirte',
  'skafe',
  'skaje',
  'skeçe',
  'skitë',
  'smagë',
  'smate',
  'snobë',
  'softa',
  'SOS-ë',
  'spate',
  'speca',
  'spola',
  'sqepa',
  'stade',
  'stafe',
  'stane',
  'stile',
  'stoqe',
  'stome',
  'sukuj',
  'sulme',
  'sytha',
  'tagra',
  'tajme',
  'takte',
  'tanke',
  'taste',
  'TEC-e',
  'tempe',
  'terma',
  'thasë',
  'thonj',
  'thukë',
  'tigra',
  'TIR-ë',
  'tituj',
  'torqe',
  'trakë',
  'trape',
  'trena',
  'trima',
  'trope',
  'troje',
  'turpe',
  'tvide',
  'tymra',
  'UEM-ë',
  'ugare',
  'ugiçë',
  'ujarë',
  'ujësa',
  'ulokë',
  'unakë',
  'urime',
  'urorë',
  'urthe',
  'vaizë',
  'vakte',
  'varfë',
  'verba',
  'verra',
  'versë',
  'vethë',
  'vëthë',
  'vinça',
  'visqe',
  'vlime',
  'vlleh',
  'vraça',
  'vrahe',
  'vuthe',
  'xhemë',
  'xhepa',
  'xhinë',
  'xhipe',
  'xhola',
  'xhupa',
  'tanët',
  'zagaj',
  'zaimë',
  'zaptë',
  'zarfe',
  'zdapa',
  'zegla',
  'zgaqë',
  'zgëqe',
  'zgota',
  'zhigë',
  'zhole',
  'zhyla',
  'zigje',
  'zvole',
  'abetë',
  'adrat',
  'aedët',
  'afçat',
  'aftat',
  'aftët',
  'ahmat',
  'ahtet',
  'ajkat',
  'AKA-t',
  'akçet',
  'aklat',
  'akrat',
  'aknet',
  'akset',
  'aktet',
  'akujt',
  'aletë',
  'alfat',
  'allet',
  'alpet',
  'altët',
  'altet',
  'altot',
  'amujt',
  'amzat',
  'anetë',
  'aniet',
  'AP-të',
  'APA-t',
  'ariet',
  'arkat',
  'armët',
  'arnat',
  'arrat',
  'artet',
  'ashet',
  'atutë',
  'aulat',
  'aurat',
  'avrat',
  'avujt',
  'axhët',
  'babot',
  'baçat',
  'bacat',
  'bafat',
  'bahet',
  'balat',
  'balot',
  'bamët',
  'bamat',
  'banat',
  'baret',
  'barët',
  'basët',
  'bazat',
  'BE-të',
  'bebet',
  'becat',
  'beget',
  'bëmat',
  'beret',
  'bërat',
  'besët',
  'bëzet',
  'bezet',
  'bibat',
  'bicat',
  'bicet',
  'bifat',
  'bigat',
  'bijat',
  'bilat',
  'bimët',
  'bijtë',
  'birat',
  'bitët',
  'bizët',
  'bizat',
  'boçat',
  'bocat',
  'bokat',
  'bonot',
  'boret',
  'bosët',
  'botët',
  'botet',
  'bovat',
  'buajt',
  'bubat',
  'bucat',
  'bufat',
  'bulat',
  'bumet',
  'bunet',
  'buqët',
  'butet',
  'butat',
  'buzot',
  'bycat',
  'bygat',
  'bypet',
  'byrët',
  'çaçat',
  'çajet',
  'caqet',
  'cakat',
  'çakat',
  'çalët',
  'çalat',
  'capat',
  'çapet',
  'çapat',
  'carët',
  'çarat',
  'CD-të',
  'çeçot',
  'ceket',
  'cekat',
  'çeket',
  'çelat',
  'cemat',
  'çemat',
  'cenet',
  'cepat',
  'çepat',
  'cëqet',
  'çetat',
  'cezat',
  'çezet',
  'CIA-t',
  'ciqet',
  'çikat',
  'cikat',
  'çilët',
  'cilat',
  'çilet',
  'cilët',
  'cimet',
  'cimat',
  'cipat',
  'çipat',
  'çojat',
  'çoqet',
  'çokat',
  'çolet',
  'çotat',
  'cubat',
  'cubet',
  'çubat',
  'çuçat',
  'cucat',
  'çukët',
  'cukat',
  'çukat',
  'culet',
  'çumat',
  'çunat',
  'cupet',
  'çupat',
  'CV-të',
  'çyçat',
  'cylet',
  'çyret',
  'cytat',
  'dacat',
  'dadat',
  'dadot',
  'dakët',
  'dakot',
  'dalat',
  'damat',
  'darët',
  'datat',
  'defet',
  'degët',
  'dejat',
  'delet',
  'dejtë',
  'demat',
  'dëmet',
  'depot',
  'dyert',
  'detet',
  'dh-të',
  'dhitë',
  'diejt',
  'digat',
  'dijet',
  'dinat',
  'dirat',
  'ditët',
  'divat',
  'doçët',
  'docet',
  'doçet',
  'dogat',
  'doket',
  'donet',
  'duart',
  'dosat',
  'dozat',
  'duatë',
  'duajt',
  'dudat',
  'dukët',
  'dumet',
  'dunat',
  'duqet',
  'ecjet',
  'edhat',
  'edhet',
  'ehujt',
  'emrat',
  'emtat',
  'engët',
  'enjat',
  'entet',
  'epjet',
  'ercët',
  'ergët',
  'esetë',
  'eshët',
  'ethet',
  'etjet',
  'eurot',
  'fajet',
  'falat',
  'faqet',
  'faret',
  'fazat',
  'femet',
  'fesat',
  'fetat',
  'fijet',
  'fiqtë',
  'finët',
  'firot',
  'fiset',
  'fitet',
  'flitë',
  'fokat',
  'fotot',
  'fugat',
  'futët',
  'futat',
  'fytet',
  'fyzat',
  'gabat',
  'gaçat',
  'gacat',
  'gaçet',
  'gafat',
  'galët',
  'galat',
  'gamat',
  'ganat',
  'garat',
  'gatat',
  'gazat',
  'geget',
  'gegët',
  'gekot',
  'gelët',
  'gemat',
  'getet',
  'getot',
  'gicat',
  'gizat',
  'gj-të',
  'glitë',
  'gnutë',
  'gocat',
  'gofet',
  'gojët',
  'golat',
  'gomat',
  'goret',
  'gosat',
  'gotat',
  'gotët',
  'grotë',
  'gratë',
  'guajt',
  'gubet',
  'gucat',
  'gufet',
  'gufat',
  'gumat',
  'gunat',
  'gupet',
  'gurët',
  'guvat',
  'gypat',
  'hajet',
  'haqet',
  'halat',
  'hanet',
  'hapat',
  'hejët',
  'hejat',
  'hejet',
  'hënat',
  'hepat',
  'hesat',
  'hidet',
  'hifet',
  'hijet',
  'himet',
  'hiret',
  'hojet',
  'hokat',
  'holet',
  'honet',
  'hopet',
  'horat',
  'hovet',
  'huajt',
  'hunët',
  'huqet',
  'hutat',
  'hyjtë',
  'hyrat',
  'idetë',
  'ikrat',
  'ikjet',
  'ikset',
  'ILO-t',
  'ilqet',
  'imtat',
  'inçët',
  'indet',
  'jajat',
  'jakët',
  'jakat',
  'jaret',
  'javët',
  'jehet',
  'jelet',
  'jenët',
  'jenat',
  'jetët',
  'jogat',
  'jonet',
  'judët',
  'kaçët',
  'kaçet',
  'kaçat',
  'kahet',
  'kakat',
  'kuajt',
  'kamat',
  'kapat',
  'kasat',
  'keçët',
  'kecat',
  'keçet',
  'kepat',
  'kezat',
  'kiçat',
  'kicat',
  'kiçet',
  'kikat',
  'kilet',
  'kilat',
  'kitet',
  'kitat',
  'kizat',
  'kobët',
  'kobat',
  'kocat',
  'koçat',
  'kocet',
  'kodet',
  'kodat',
  'kojet',
  'kokot',
  'komet',
  'komat',
  'konet',
  'koqet',
  'korat',
  'kosat',
  'kotat',
  'kovat',
  'kritë',
  'KS-të',
  'kubet',
  'kuçet',
  'kupat',
  'kuqet',
  'kuqot',
  'kurat',
  'kutet',
  'kuzet',
  'kyçet',
  'labët',
  'labet',
  'laçet',
  'lacat',
  'lagat',
  'leqet',
  'lalët',
  'lalat',
  'lamat',
  'lapat',
  'larat',
  'larët',
  'larot',
  'lasat',
  'lasot',
  'latat',
  'lavat',
  'lehet',
  'lejet',
  'lekët',
  'lelat',
  'lemat',
  'lënat',
  'lerat',
  'lesat',
  'levat',
  'lezët',
  'licat',
  'lidët',
  'lilat',
  'limat',
  'liqtë',
  'liqet',
  'lirat',
  'lisat',
  'lizat',
  'll-të',
  'lobet',
  'locët',
  'locet',
  'loçot',
  'loget',
  'logot',
  'loket',
  'lolot',
  'lopët',
  'losat',
  'lotët',
  'lozet',
  'lulet',
  'lumet',
  'lutat',
  'lylat',
  'lymet',
  'lypat',
  'lyrat',
  'macet',
  'majat',
  'makat',
  'malet',
  'manat',
  'mapot',
  'mazat',
  'meçet',
  'mëmat',
  'meset',
  'mesat',
  'metat',
  'mëzet',
  'micat',
  'miçat',
  'miqtë',
  'miket',
  'mimat',
  'minat',
  'mirat',
  'mitet',
  'mitat',
  'mizat',
  'modat',
  'molet',
  'molat',
  'morat',
  'motot',
  'muajt',
  'muret',
  'muzat',
  'mycat',
  'myjat',
  'myqet',
  'myset',
  'najat',
  'namet',
  'napat',
  'netët',
  'nazet',
  'neçet',
  'nefet',
  'nëmat',
  'nenet',
  'nenat',
  'nënat',
  'nimet',
  'nj-të',
  'nocat',
  'nomet',
  'norët',
  'notat',
  'nulat',
  'numat',
  'nunët',
  'nunat',
  'nuret',
  'nuset',
  'nyejt',
  'nyjat',
  'oazat',
  'obuat',
  'ojmet',
  'ojnat',
  'okjet',
  'optet',
  'orlët',
  'ortat',
  'ostet',
  'paçet',
  'pagat',
  'pajat',
  'pakot',
  'pamat',
  'panët',
  'papët',
  'parat',
  'pasat',
  'patet',
  'patat',
  'PD-të',
  'pecet',
  'peçet',
  'pefat',
  'pelat',
  'pemët',
  'penat',
  'petat',
  'petët',
  'picat',
  'pijet',
  'pilat',
  'pinat',
  'pipat',
  'pisat',
  'pitet',
  'poçet',
  'pohet',
  'ponët',
  'popët',
  'popet',
  'poret',
  'potat',
  'pozat',
  'PR-të',
  'PS-të',
  'psetë',
  'puçet',
  'pudët',
  'pufet',
  'pulat',
  'pumat',
  'punët',
  'pupat',
  'purot',
  'puset',
  'putet',
  'putat',
  'pykat',
  'pyjet',
  'qafat',
  'qagat',
  'qapat',
  'qarat',
  'qaset',
  'qasat',
  'qazët',
  'qebet',
  'qelat',
  'qentë',
  'qenet',
  'qepët',
  'qeret',
  'qeset',
  'qetet',
  'qetat',
  'qezat',
  'qiejt',
  'qimet',
  'qokët',
  'qokat',
  'qolet',
  'qoret',
  'qukat',
  'qylat',
  'qypat',
  'qypet',
  'qyqet',
  'qyrat',
  'qytat',
  'racat',
  'rajat',
  'ramët',
  'ratat',
  'ravat',
  'rejat',
  'rekët',
  'renat',
  'rënat',
  'rënët',
  'rërat',
  'resat',
  'rezet',
  'rezat',
  'rigat',
  'rikat',
  'rimet',
  'rimat',
  'risët',
  'rizat',
  'rodet',
  'rogat',
  'rojet',
  'rojat',
  'rolet',
  'rolat',
  'rolot',
  'romët',
  'romet',
  'ronet',
  'rosat',
  'rozat',
  'rr-të',
  'rretë',
  'rubat',
  'rucët',
  'rudat',
  'runat',
  'rupet',
  'rusët',
  'rutat',
  'ryzat',
  'saçet',
  'safat',
  'sagat',
  'sajat',
  'sakët',
  'sapet',
  'sasat',
  'sazet',
  'seret',
  'serat',
  'sërët',
  'setet',
  'sh-të',
  'sidat',
  'sisët',
  'sitat',
  'sobat',
  'sojet',
  'sojat',
  'solot',
  'sopat',
  'spitë',
  'sukat',
  'sulat',
  'sulet',
  'supet',
  'supat',
  'suret',
  'sutat',
  'sykat',
  'syzet',
  'syzat',
  'taçet',
  'tajat',
  'takët',
  'taket',
  'tapat',
  'taret',
  'tarat',
  'tatat',
  'tavat',
  'tehet',
  'tekat',
  'telat',
  'temat',
  'tenët',
  'tenet',
  'tepët',
  'tepet',
  'terat',
  'tetot',
  'tezet',
  'tezat',
  'th-të',
  'thitë',
  'tijtë',
  'tijat',
  'timat',
  'tinat',
  'tipat',
  'tiret',
  'tirat',
  'tisat',
  'toçat',
  'togat',
  'tojat',
  'tokat',
  'topat',
  'tozet',
  'triot',
  'trutë',
  'tubat',
  'tufat',
  'tugat',
  'tujet',
  'tujat',
  'tulet',
  'tumat',
  'tutat',
  'tylet',
  'tytat',
  'ublat',
  'udhët',
  'uebet',
  'UFO-t',
  'uljet',
  'ulzat',
  'umbet',
  'UP-të',
  'urnat',
  'USA-t',
  'UT-të',
  'uvjet',
  'uxhat',
  'vadat',
  'vajat',
  'vanat',
  'vasat',
  'vazet',
  'vazot',
  'vegët',
  'velat',
  'venat',
  'verat',
  'vërat',
  'veset',
  'vesat',
  'vetat',
  'vetot',
  'vezët',
  'vicet',
  'viçat',
  'viçet',
  'vidat',
  'vigat',
  'vijat',
  'vinat',
  'viret',
  'viset',
  'vocat',
  'vocet',
  'volat',
  'vomet',
  'vonat',
  'votat',
  'vovat',
  'vozat',
  'vulat',
  'vutat',
  'WC-të',
  'xanat',
  'xehet',
  'xelat',
  'xh-të',
  'xinat',
  'xipat',
  'xixat',
  'xuqët',
  'xuqet',
  'yllet',
  'yrtet',
  'ystet',
  'zadet',
  'zaret',
  'zazat',
  'zërat',
  'zebat',
  'zejet',
  'zekat',
  'zerot',
  'zezat',
  'zh-të',
  'zilet',
  'zogat',
  'zotat',
  'zotët',
  'zuçët',
  'zybat',
  'zyrat',
  'pesat',
  'tetat',
  'trarë',
]
