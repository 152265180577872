export const GAME_TITLE = 'FJALËZ'

export const WIN_MESSAGES = ['Punë e shkëlqyer!', 'E mrekullueshme', 'Bravo!']
export const GAME_COPIED_MESSAGE = 'Fjalëz u kopjua në clipboard'
export const ABOUT_GAME_MESSAGE = 'Rreth kësaj loje'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Mungojnë shkronja'
export const WORD_NOT_FOUND_MESSAGE = 'Fjala nuk u gjet'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Fjala e sakt ishte ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Statistikat'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'Fjalë e re në'
export const SHARE_TEXT = 'Shperndaje'
export const TOTAL_TRIES_TEXT = 'Gjithsej përpjekje'
export const SUCCESS_RATE_TEXT = 'Shkalla e suksesit'
export const CURRENT_STREAK_TEXT = 'Rrjedha aktuale'
export const BEST_STREAK_TEXT = 'Rrjedha më e mirë'
